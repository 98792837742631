import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  ButtonGroup,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  Grid,
  TextareaAutosize,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  IconButton,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  ArrowDropDown,
  AssignmentOutlined,
  CalendarMonthOutlined,
  CheckBoxOutlineBlank,
  CreditCard,
  PersonOutline,
} from "@mui/icons-material";
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import QRCode from "react-qr-code";
import axios from "axios";
import { io } from "socket.io-client";
import EditCustomerSeats from "./EditCustomerSeats";
import { Controller, FieldError, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { ReservesService } from "../../services/ReservesService";
import {
  DatePicker,
  dayCalendarSkeletonClasses,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const socket = io(`${process.env.REACT_APP_API_URL}`, {
  transports: ["websocket", "polling"],
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 19,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 19,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "เลขจอง",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "name_customer",
    headerName: "ชื่อลูกค้า",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "resv_time_start",
    headerName: "เวลาเข้า",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "resv_time_end",
    headerName: "เวลาออก",
    headerAlign: "center",
    align: "center",
    flex: 1,
    // renderCell: () => <Box>T-1</Box>,
  },
  {
    field: "customer_count",
    headerName: "จำนวน",
    headerAlign: "center",
    align: "center",
    // renderCell: () => (
    //   <Box display={"flex"} justifyContent={"center"}>
    //     <PersonOutline fontSize="small" />
    //     12
    //   </Box>
    // ),
  },
  {
    field: "status",
    headerName: "",
    headerAlign: "center",
    width: 5,
    align: "center",
    renderCell: (row) => <CreditCard style={{ color: "#8e3dfd" }} />,
  },
];

// const rows = [
//   { id: "R-001", name: "หมู", resv_start: "08:01", resv_end: "10:00", qty: 4 },
//   { id: "R-002", name: "แมว", resv_start: "10:01", resv_end: "12:00", qty: 4 },
// ];

export default function TableResvOnCustomers(props: any) {
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [isDailog, setIsDialog] = React.useState<boolean>(false);
  const [isStatus, setIsStatus] = React.useState<boolean>(false);
  const [fullWidth, setFullWidth] = React.useState<boolean>(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const [token, setToken] = React.useState("");
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const today = dayjs().startOf("day"); // วันนี้ในรูปแบบเริ่มต้นวัน
  // เช็คว่ามีการเลือกวันที่ที่ไม่ใช่วันนี้
  const [isNotToday, setIsNotToday] = React.useState<boolean>(false);

  const [rows, setRows] = React.useState<Array<any>>([]);

  const [action, setAction] = React.useState<number>(0);

  const [dateSearch, setDateSearch] = React.useState(dayjs());
  const [idResv, setIdResv] = React.useState("");

  React.useEffect(() => {
    console.log(action);
    if (action === 0) {
      reset();
      setValue("resv_date", today);
      setSelectedDate(today);
      setIsStatus(false);
    }
    setValue("tableId", props.selectedTable.id);
    setValue("nameTable", props.selectedTable.name);
  }, [action, isDailog]);

  React.useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/tables/get/token`, {
        table_id: props.selectedTable.id,
      })
      .then((response) => {
        if (response.data.message == "OK") {
          setToken(response.data.token);
          setLoaded(true);
        }
      });
  }, [props]);

  React.useEffect(() => {
    setDateSearch(today);
    getRows(today.toString());
    setIsStatus(false);
  }, []);

  const getRows = async (dateView: string) => {
    const date = dayjs(dateView);

    // Format date
    const formattedDate = date.format("YYYY-MM-DD");
    console.log("Formatted Date:", formattedDate);

    const data = {
      id: props.selectedTable.id,
      resv_date: formattedDate,
    };
    const res = await ReservesService.getDataTabel(data);
    if (res?.status == 200) {
      const { data } = res.response;
      console.log(JSON.stringify(data));
      setRows(data);
    }
  };

  const addData = () => {
    reset(); 
    setIdResv("");
    setValue("resv_date", today);
    setSelectedDate(today);
    setIsStatus(false);

    setValue("register", "reserve");
    setValue("nameCustomer", "");
    setValue("customerCount", "");
    setValue("email", "");
    setValue("telephone", "");
    setValue("detail", ""); 
    // setValue("resv_time_start", "");
    // setValue("resv_time_end", "");
    setValue("amount", "");
    setIsNotToday(false);
    setAction(0);
    setIsDialog(true);
  };

  const onSubmit = async (data: any) => {
    console.log("Form Data: " + JSON.stringify(data));
    const date = dayjs(data.resv_date);

    const formattedDate = date.format("YYYY-MM-DD");
    console.log("Formatted Date:", formattedDate);

    data.resv_date = formattedDate;

    try {
      // เรียกใช้งานบริการเพื่อบันทึกข้อมูล
      if (idResv) {
        const res = await ReservesService.updateData(idResv, data);

        if (res?.status == 200) {
          const { data } = res.response;
          //props.onResponse(data, false);
          Swal.fire({
            icon: "success",
            text: res?.message,
          });
          //props.onClose();
          getRows(dateSearch.toString());
          setIsDialog(false);
        } else {
          Swal.fire({
            icon: "error",
            text: res?.message,
          });
        }
      } else {
        const res = await ReservesService.saveData(data);

        if (res?.status == 200) {
          const { data } = res.response;
          //props.onResponse(data, false);
          Swal.fire({
            icon: "success",
            text: res?.message,
          });
          //props.onClose();
          getRows(dateSearch.toString());
          setIsDialog(false);
          props.reloadTables();
        } else {
          Swal.fire({
            icon: "error",
            text: res?.message,
          });
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        text: "An error occurred while saving the data.", // ข้อความแสดงข้อผิดพลาดในกรณีที่เกิดข้อผิดพลาด
      });
    }
  };

  const onUpdateStatus = async () => {
    try {
      // เรียกใช้งานบริการเพื่อบันทึกข้อมูล
      const res = await ReservesService.updateStatus(idResv, watch("status"));

      if (res?.status == 200) {
        // const { data } = res.response;
        //props.onResponse(data, false);
        Swal.fire({
          icon: "success",
          text: res?.message,
        });
        //props.onClose();
        getRows(dateSearch.toString());
        setIsDialog(false);
        props.reloadTables();
      } else {
        Swal.fire({
          icon: "error",
          text: res?.message,
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        text: "An error occurred while saving the data.", // ข้อความแสดงข้อผิดพลาดในกรณีที่เกิดข้อผิดพลาด
      });
    }
  };
 
  return (
    <Box sx={{ p: 5 }}>
      <Dialog
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setIsDialog(false);
          }
        }}
        open={isDailog}
        fullWidth
        PaperProps={{ sx: { borderRadius: "13px" } }}
      >
        <DialogTitle id="form-dialog-title">
          จองโต๊ะ : {props.selectedTable.name}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 3 }}>
          {action !== 0 && (
            <Grid container sx={{ pt: 2 }}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  สถานะ
                </InputLabel>
                <Select
                  disabled={!isStatus}
                  displayEmpty
                  defaultValue={watch("status")}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "45px", width: "100px" }}
                  {...register("status", { required: true })}
                >
                  <MenuItem value="reserve">จอง</MenuItem>
                  <MenuItem value="usering">ใช้งาน</MenuItem>
                  <MenuItem value="cancel">ยกเลิก</MenuItem>
                </Select>
              </FormControl>
              {isStatus && (
                <IconButton
                  style={{
                    backgroundColor: "#f8f9fd",
                    width: "40px",
                    height: "40px",
                    borderRadius: 7,
                    left: "10px",
                  }}
                  onClick={() => onUpdateStatus()}
                >
                  <AssignmentOutlined style={{ color: "#8e3dfd" }} />
                </IconButton>
              )}
            </Grid>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ pt: 2 }}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  ชื่อ - นามสกุล
                </InputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  {...register("nameCustomer", { required: true })}
                />
              </FormControl>
              &nbsp;&nbsp;
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  จำนวนคน
                </InputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  {...register("customerCount", { required: true })}
                />
              </FormControl>
            </Grid>
            <Grid container sx={{ pt: 2 }}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  อีเมล
                </InputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  {...register("email", {  
                    required: "จำเป็นต้องกรอกอีเมล",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "รูปแบบอีเมลไม่ถูกต้อง"
                    }
                   })}
                />
                {/* ตรวจสอบและแสดงข้อความ error เฉพาะเมื่อมี */}
                {errors.email && typeof errors.email.message === 'string' && (
                  <p>{errors.email.message}</p>
                )}
     
              </FormControl>
              &nbsp;&nbsp;
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  เบอร์โทรศัพท์
                </InputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  {...register("telephone", { required: true })}
                />
              </FormControl>
            </Grid>
            <br />
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bootstrap-input">
                รายละเอียด
              </InputLabel>
              <BootstrapTextareaAutosize
                placeholder="รายละเอียด"
                style={{ borderRadius: 19, padding: 10 }}
                minRows={3}
                {...register("detail", { required: true })}
              />
            </FormControl>
            <br />
            <FormControl variant="standard" sx={{ p: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วันเวลาจอง"
                  value={selectedDate}
                  onChange={(newValue: any) => {
                    setSelectedDate(newValue);

                    setValue("resv_date", newValue);
                    const checkToDay = !newValue.isSame(today, "day");
                    setIsNotToday(checkToDay);
                    setValue("status_date", checkToDay);

                    setValue("amount", "");
                    // setValue("resv_date_start", newValue);

                    // setValue("resv_time_start", newValue);
                  }}
                  disablePast
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl variant="standard" sx={{ p: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  // disablePast
                  label="เวลาเข้า"
                  value={watch("resv_time_start")}
                  onChange={(newValue: any) => {
                    setValue("resv_time_start", newValue);
                  }}
                  ampm={false} // This ensures 24-hour format
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl variant="standard" sx={{ p: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  // disablePast
                  label="ถึง"
                  value={watch("resv_time_end")}
                  onChange={(newValue: any) => {
                    setValue("resv_time_end", newValue);
                  }}
                  ampm={false} // This ensures 24-hour format
                  // minTime={minTime}
                />
              </LocalizationProvider>
            </FormControl>
            <br />
            {isNotToday && (
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  จำนวนเงิน
                </InputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  {...register("amount", { required: isNotToday })}
                />
              </FormControl>
            )}
            <DialogActions sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    fullWidth
                    size={"large"}
                    style={{
                      border: "1px solid #ff6801",
                      color: "#ff6801",
                      borderRadius: 25,
                      padding: "10px 35px 10px 35px",
                    }}
                    onClick={() => {
                      setIsDialog(false); 
                      props.reloadTables();
                    }}
                  >
                    ยกเลิก
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {action === 0 ? (
                    <Button
                      fullWidth
                      size={"large"}
                      type="submit" // กด submit ฟอร์ม
                      style={{
                        backgroundColor: "#ff6801",
                        color: "white",
                        borderRadius: 25,
                        padding: "10px 35px 10px 35px",
                      }}
                    >
                      บันทึก
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      disabled={isStatus || watch("status") !== "reserve"}
                      size={"large"}
                      type="submit" // กด submit ฟอร์ม
                      style={{
                        backgroundColor:
                          !isStatus && watch("status") === "reserve"
                            ? "#ff6801"
                            : "#dad2cc",
                        color: "white",
                        borderRadius: 25,
                        padding: "10px 35px 10px 35px",
                      }}
                    >
                      บันทึก
                    </Button>
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {/* <Box textAlign={'right'} >
                <FormControl sx={{ mt: 2, pr: 1 }}>
                    <Button fullWidth size={"large"} style={{ backgroundColor: "#7b69e8", color: "white", borderRadius: 10 }} >ย้ายโต๊ะ / จองโต๊ะ</Button>
                </FormControl>

                <FormControl sx={{ mt: 2, pr: 1 }}>
                    <Button fullWidth size={"large"} style={{ backgroundColor: "#e2e2e2", color: "black", borderRadius: 10 }} >ส่งไปยังครัว</Button>
                </FormControl>
            </Box> */}

      {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#3395f0',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid #91caff'
                }}></div>&nbsp;&nbsp;มีอยู่</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#8e3dfd',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(190 151 245)'
                }}></div>&nbsp;&nbsp;จองแล้ว</div>
                &nbsp;&nbsp;

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#14c91c',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(207 238 208)'
                }}></div>&nbsp;&nbsp;เรียกเก็บเงินแล้ว</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#f0b433',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(253 219 147)'
                }}></div>&nbsp;&nbsp;กำลังว่าง</div>
            </div> */}

      <Box
        pt={2}
        pb={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>โต๊ะ : {props.selectedTable.name}</Box> {/* ข้อความทางซ้าย */}
        <Button
          onClick={() => addData()}
          variant="contained"
          style={{ background: "#13ad13", color: "white" }}
        >
          + จองโต๊ะ
        </Button>{" "}
        {/* ปุ่มทางขวา */}
      </Box>

      <br></br>

      <div
        style={{
          color: "#7a7a7a",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>เลือกวันที่</span>
        <span
          style={{
            verticalAlign: "middle",
            display: "inline-flex",
          }}
        >
          {/* March 2023 <ArrowDropDown></ArrowDropDown> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="วันที่จอง"
              value={dateSearch}
              onChange={(newValue: any) => {
                setDateSearch(newValue);
                getRows(newValue.toString());
              }}
              disablePast
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        </span>
      </div>

      <br></br>
      <Box textAlign={"center"}>
        <ButtonGroup
          sx={{
            ".MuiButtonGroup-grouped": {
              borderColor: "#ccc",
            },
          }}
          variant="outlined"
        >
          <Button
            style={{ borderTopLeftRadius: 14, borderBottomLeftRadius: 14 }}
          >
            <CalendarMonthOutlined
              style={{ color: "#ff7900" }}
            ></CalendarMonthOutlined>
          </Button>
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
            (item, index) => {
              return (
                <Button
                  style={
                    index == 6
                      ? {
                          borderTopRightRadius: 14,
                          borderBottomRightRadius: 14,
                        }
                      : {}
                  }
                >
                  <Box display={"grid"}>
                    <div
                      style={{
                        fontSize: 12,
                        color: "#a7a7a7",
                        fontWeight: 600,
                        textTransform: "capitalize",
                      }}
                    >
                      {item}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: 16,
                      }}
                    >
                      {index + 2}
                    </div>
                  </Box>
                </Button>
              );
            }
          )}
        </ButtonGroup>
      </Box>

      <br></br>
      <Box
        sx={{ width: { xs: 400, sm: 400, md: "100%" } }}
        style={{ overflow: "auto" }}
      >
        <DataGrid
          sx={{
            width: { xs: 400, sm: 400, md: "100%" },
            border: 0,
          }}
          // getRowId={(row: GridRowModel) => row.id}
          // getRowHeight={() => 'auto'}
          disableEval
          // showCellVerticalBorder={true}
          disableColumnMenu
          disableColumnFilter
          rows={rows}
          columns={columns}
          hideFooter
          onCellClick={(e: any) => {
            const rowData = e.row;
            setValue("status", rowData.status);
            setValue("nameCustomer", rowData.name_customer);
            setValue("customerCount", rowData.customer_count);
            setValue("email", rowData.email);
            setValue("telephone", rowData.telephone);
            setValue("detail", rowData.detail);

            const dateValue = rowData.resv_date;
            setSelectedDate(dayjs(dateValue));
            setValue("resv_date", dateValue);

            setValue("amount", rowData.amount);
            setIdResv(rowData.id);
            setAction(1);

            setValue(
              "resv_time_start",
              dayjs(rowData.resv_time_start, "HH:mm")
            );
            setValue("resv_time_end", dayjs(rowData.resv_time_end, "HH:mm"));

            setIsNotToday(rowData.status_date === 0 ? false : true);
            setValue("status_date", rowData.status_date);
            // Check which column was clicked
            const clickedField = e.field; // Get the clicked column's field if available

            if (
              clickedField &&
              [
                "id",
                "name_customer",
                "resv_time_start",
                "resv_time_end",
                "customer_count",
              ].includes(clickedField)
            ) {
              console.log("Clicked column:", clickedField);
              setIsStatus(false);
              setIsDialog(true); // Show dialog only for specific columns
            } else {
              if (rowData.status === "reserve") {
                setIsDialog(true);
                setIsStatus(true);
              }

              console.log("Clicked other column:", clickedField);
            }
          }}
        />
      </Box>
      <br></br>

      <br />
      {/* <Grid container justifyContent="center">
        {props.selectedTable.haved_token == 1 && (
          <>
            <Select
              required
              onChange={onChangeMoveTable}
              value={idMove}
              displayEmpty
              size={"small"}
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: 10,
                color: "black",
                minWidth: 150,
                width: "100%",
              }}
            >
              {props.listTable
                .filter((x: any) => x.haved_token === 0)
                .map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <Button
              onClick={onClickTableMove}
              variant="contained"
              style={{
                color: "white",
              }}
            >
              ย้ายโต๊ะ
            </Button>
          </>
        )}
      </Grid>
      <hr></hr>
      <br /> */}
      {/* <br /> */}
      {/* <Grid container justifyContent="center">
        <Button
          onClick={onclickremove}
          variant="contained"
          style={{
            color: "white",
          }}
        >
          พนักงานมาถึงโต๊ะแล้ว
        </Button>
      </Grid> */}
    </Box>
  );
}
