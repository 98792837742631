import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";

import axios from "axios";
import Cookies from "universal-cookie";

export default function Login() {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    text: "",
    status: "",
  });
  const usernameRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);

  const [employeeStatus, setEmployeeStatus] = useState<boolean>(false);
  useEffect(() => {
    if (cookies.get("employee_token")) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/employee/check`,
          {},
          {
            headers: {
              authorization: cookies.get("employee_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Token is vailded") {
            setEmployeeStatus(true);
            navigate("/");
          }
        });
    }
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    axios
      .post(`${process.env.REACT_APP_API_URL}/employee/login`, {
        username: username,
        password: password,
      })
      .then((response: any) => {
        if (response.data.message == "OK") {
          cookies.set("employee_token", response.data.token);
          navigate("/");
        } else {
          setDialogContent({
            title: "เกิดข้อผิดพลาด",
            text: response.data.message,
            status: "error",
          });
          setIsDialogOpen(true);
        }
      });
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: "#e6e6e6",
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <Paper>
                <Grid
                  direction={"column"}
                  justifyItems={"center"}
                  rowSpacing={2}
                  sx={{ padding: "40px" }}
                  container
                  alignContent={"center"}
                >
                  <Grid xs={12} item={true}>
                    <InputLabel htmlFor="emailOrPhone">Username</InputLabel>
                    <FormControl
                      size={"small"}
                      fullWidth={true}
                      sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="emailOrPhone"
                        name="emailOrPhone"
                        inputRef={usernameRef}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} item={true}>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <FormControl
                      size={"small"}
                      fullWidth={true}
                      sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        inputRef={passwordRef}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      fullWidth={true}
                      variant={"contained"}
                      type="submit"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
