import { Axios } from '../shared/Axios';
import qs from 'qs';

class ReservesService extends Axios {

    private static instance: ReservesService;

    static get(): ReservesService {
        if (!ReservesService.instance) {
            ReservesService.instance = new ReservesService();
        }
        return ReservesService.instance;
    }

    async getData(): Promise<any> {
        return this.instance.get(`/reserves`)
    }

    async saveData(data: object): Promise<any> {
        return this.instance.post(`/reserves`, data)
    }

    async deleteData(id: string): Promise<any> {
        return this.instance.delete(`/reserves/${id}`)
    }

    async updateData(id: string, data: object): Promise<any> {
        return this.instance.patch(`/reserves/${id}`, data)
    } 

    async getDataTabel(data: Object): Promise<any> {
        return this.instance.post(`/reserves/table`, data) 
    }

    async getDataSearch(data: Object): Promise<any> {
        return this.instance.post(`/reserves/search`, data) 
    }

    async updateStatus(id: string, status: string): Promise<any> {
        return this.instance.get(`/reserves/update-status/${id}/${status}`)
    }
    
}


const reservesService = ReservesService.get();

export { reservesService as ReservesService }