import React, { ChangeEvent, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LuClipboardList } from "react-icons/lu";
import { GoChecklist } from "react-icons/go";
import { SiAddthis } from "react-icons/si";
import axios from "axios";
import Cookies from "universal-cookie";
import AddEmployee from "./AddEmployee";
import Swal from 'sweetalert2';

export default function Employee() {
  const cookies = new Cookies(null, { path: '/' });
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedJob, setSelectedJob] = useState<string>("");      
  const [employee, setEmployee] = useState<any>([]);
  const [isOpenDialogAddEmployee, setIsOpenDialogAddEmployee] = useState<boolean>(false);

  useEffect(() => {
    reloadEmployee();
  }, []);

  const reloadEmployee = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/employee/get/all`, {
      headers: {
        authorization: cookies.get('employee_token')
      }
    }).then((response) => {
      if(response.data.message == 'OK')
      {
        setEmployee(response.data.employee);
      }
    })
  }

  const deleteEmployee = (employee_id:number) => {
    const findIdx_Employee = employee.findIndex((element:any) => {
      return element.id == employee_id
    })

    if(findIdx_Employee == -1)
    {
      return Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาด ไม่พบพนักงานนี้ในข้อมูล"
      });;
    }

    Swal.fire({
      title: "ยืนยันหรือไม่ ?",
      text: `แน่ใจหรือไม่ ว่าจะลบพนักงานชื่อผู้ใช้ ${employee[findIdx_Employee].username}`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!"
    }).then((result) => {
      if(result.isConfirmed)
      {
        axios.post(`${process.env.REACT_APP_API_URL}/employee/delete`, {
          employee_id: employee_id
        }, {
          headers: {
            authorization: cookies.get('employee_token')
          }
        }).then((response:any) => {
          if(response.data.message == 'OK')
          {
            reloadEmployee();
            Swal.fire({
                icon: "success",
                text: 'ลบพนักงานเรียบร้อยแล้ว'
            });
          }
          else
          {
            Swal.fire({
              icon: "error",
              text: response.data.message
            });
          }
        })
      }
    })
  }

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(event.target.value);
  };

  const handleJob = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedJob(event.target.value);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container sx={{ flexGrow: 1, width: '100%'}}>
      <AddEmployee isOpenDialog={isOpenDialogAddEmployee} setIsOpenDialogAddEmployee={setIsOpenDialogAddEmployee} employee_token={cookies.get('employee_token')} reloadEmployee={reloadEmployee} />
      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{ bgcolor: "#FFB802", 
      width: "100%", 
      mb: 2, 
      mt: 0,
      ml: { xs: 0, md: 0},
      p: 3,
      }}>
      <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
        พนักงาน
      </Typography>
    </Grid>

    <Paper sx={{ width: { xs: '95%', md: '98%' }, p: 5, ml: { xs: 1, md: 2}, mt: -1 }}>
  <Grid container spacing={2}>
    <Grid item xs={12} md={6} sx={{mt: {xs: 0, md: -4},ml: {xs: 0, md:-3}}}>
      <Typography>ชื่อผู้ใช้</Typography>
      <TextField
        sx={{
          width: {xs: '100%', md: '291px'},
          height: '42px',
          borderRadius: '8px',
          mb: { xs: 2, md: 0 },
        }}
        variant="outlined"
        fullWidth
        value={selectedName}
        onChange={handleName}
      />
    </Grid>

    <Grid item xs={12} md={6} sx={{mt: {xs: 0, md: -10.5},ml: {xs: 0, md: 35}}}>
      <Typography>ตำแหน่ง</Typography>
      <TextField
        sx={{
          width: {xs: '100%', md: '291px'},
          height: '42px',
          borderRadius: '8px',
          mb: { xs: 2, md: 0 },
        }}
        variant="outlined"
        fullWidth
        value={selectedJob}
        onChange={handleJob}
      />
    </Grid>

    <Grid item xs={12} md={3} sx={{mt: {xs: 0 , md: -6.7 }, ml:{xs: 0, md: 75}}}>
      <Button
        size="small"
        color="inherit"
        style={{ borderRadius: 4, background: '#FF9B28', color: '#FFFFFF',padding: '9px 10px 9px 10px' }}
        sx={{
          width: {xs: '100%', md: '151px'},
          height: '42px',
          fontSize: '15px',
        }}
      >
        ค้นหา
      </Button>
    </Grid>
        </Grid>
        <Grid item xs={12} md={3} container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{mt: {xs: 2, md:5}, ml:{xs: -5,sm:-1 , md: -3,lg: -2}}}>
      <Typography sx={{
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        overflowWrap: 'break-word',
     }}>
    คุณมีพนักงานทั้งหมด {employee.length} คน
  </Typography>
</Grid>

<Grid item xs={12} md={3} container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{mt: {xs: 2, md:3}, ml:{xs: 0, md: -2}}}>
      <Button
        size="small"
        color="inherit"
        style={{ borderRadius: 4, background: '#79C447', color: '#FFFFFF' }}
        sx={{
          width: {xs: '100%', md: '146px'},
          height: '40px',
          fontSize: '15px',
        }}
        onClick={() => {
          setIsOpenDialogAddEmployee(true);
        }}
      >
        <IconButton color="inherit" size="medium">
          <SiAddthis />
          </IconButton>
            เพิ่มพนักงาน
          </Button>
          </Grid>

</Paper>


<Grid item xs={12} md={12} sx={{ mt: 2, overflowX: 'auto', marginLeft: { xs: 1, sm: 2, md: 2 } }}>
        <Table sx={{ borderRadius: '8px', width: { xs: '100%', sm: '100%', md: '99%' } }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '8px 0px 0px 0px' }}>ลำดับ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ชื่อ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ตำแหน่ง</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ข้อมูลการติดต่อ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ค่าจ้าง</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>สถานะ/สิทธิ์</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '0px 8px 0px 0px' }}>จัดการ</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: 'column' } : {}}>
            {employee.map((row:any, index:any) => (
              <TableRow key={index} sx={isMobile ? { flexDirection: 'column' } : {}}>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.id}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.username}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.position_name}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.address}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.salary}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.permissions}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>
                  <IconButton color="primary" aria-label="edit">
                    <GoChecklist style={{color: '#FFFFFF', background: '#FFB969', width: '37.77px', height:'36.94px', borderRadius: '8px', padding: '5px'}}/>
                  </IconButton>
                  <IconButton color="secondary" aria-label="view">
                  <LuClipboardList style={{color: '#FFFFFF', background: '#B8DDFF',width: '37.77px', height:'36.94px', borderRadius: '8px', padding: '5px'}}/>
                  </IconButton>
                  <IconButton color="default" aria-label="delete" onClick={() => {
                    deleteEmployee(row.id)
                  }}>
                    <DeleteOutlineIcon style={{color: '#FFFFFF', background: '#FF9696',width: '37.77px', height:'36.94px', borderRadius: '8px', padding: '5px'}}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
    </Grid>
  );
}