import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TaskList from "../../Icon/TaskList";
import { IconButton, Stack } from "@mui/material";
import CustomEditModal from "./CustomEditModal";
import { useEffect, useState } from "react";
import Stock from "../../../models/stock";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E7E8F2",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".MuiTableCell-root": {
    borderLeft: "1px solid #D9D9D9",
  },
}));

function createData(
  id: number,
  productCode: string,
  productName: string,
  stockCut: string,
  cost: number,
  price: number,
  profit: number,
  sold: number,
  unit: number
) {
  return {
    id,
    productCode,
    productName,
    stockCut,
    cost,
    price,
    profit,
    sold,
    unit,
  };
}

const stocks = [
  createData(
    0,
    "SS000333",
    "กระเทียมเจียว",
    "กระเทียมไทย 50 กิโลกรัม",
    0,
    0.0,
    0,
    0,
    0
  ),
  createData(
    0,
    "SS000333",
    "กระเทียมเจียว",
    "กระเทียมไทย 50 กิโลกรัม",
    0,
    0.0,
    0,
    0,
    0
  ),
  createData(
    0,
    "SS000333",
    "กระเทียมเจียว",
    "กระเทียมไทย 50 กิโลกรัม",
    0,
    0.0,
    0,
    0,
    0
  ),
  createData(
    0,
    "SS000333",
    "มะระกอ",
    "กระเทียมไทย 50 กิโลกรัม",
    0,
    0.0,
    0,
    0,
    0
  ),
  createData(0, "SS000333", "น้ำมัน", "น้ำมัน 10 ลิตร", 0, 0.0, 0, 0, 0),
];

export default function StockTableBody() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [stock, setStock] = useState<Stock>();

  useEffect(() => {
    if (stock) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [stock]);

  return (
    <>
      <TableBody>
        {stocks.map((stock) => (
          <StyledTableRow key={stock.id}>
            <StyledTableCell component="th" scope="row" align="center">
              {stock.productCode}
            </StyledTableCell>
            <StyledTableCell align="center">
              {stock.productName}
            </StyledTableCell>
            <StyledTableCell
              sx={{ minWidth: "300px" }}
              padding="none"
              align="center"
            >
              {stock.stockCut}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              {stock.cost}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              {stock.price}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              {stock.profit}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              {stock.sold}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              {stock.unit}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ flexGrow: 1, mx: 2 }}
              >
                <IconButton
                  sx={{
                    bgcolor: "#FFB969",
                    borderRadius: "8px",
                    ":hover": { bgcolor: "#FFB969" },
                    mr: 2,
                  }}
                  onClick={() => {
                    setStock(stock);
                  }}
                >
                  <TaskList />
                </IconButton>
              </Stack>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
      {stock && (
        <CustomEditModal
          isOpen={isOpenModal}
          stock={stock}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  );
}
