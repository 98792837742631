

import styled from 'styled-components';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useEffect, useRef, useState } from 'react';
const CenteredSquare = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 250px;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 100%;
  max-height: 200px;
  border: 2px solid #f1f1f1;
  border-radius:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 2px solid #8e3dfd;
  }
`;

const OvalChair = styled.div`
    position: absolute;
    width: 15px;
    height: 30px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
`;

const LeftChair = styled(OvalChair)`
  left: -21px;
  transform: rotate(-1deg);
`;

const RightChair = styled(OvalChair)`
  right: -21px;
  transform: rotate(1deg);
`;

const TopChair = styled(OvalChair)`
  top: -31px;
  transform: rotate(270deg);
`;

const BottomChair = styled(OvalChair)`
  bottom: -30px;
  transform: rotate(-270deg);
`;

const TableNumber = styled.span`
  font-size: 18px;
  font-weight: bold;
  background: #d0e9ff;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

interface Props {
  tableName: string
  color: string
  style?: React.CSSProperties
  onClick?: () => void;
}
interface UpdatedProps extends Props {
  alram: string[];
}


export default function DiningTable12(UpdatedProps: UpdatedProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;
    togglePlay()
    const handlePlaybackEnded = () => {
      setIsPlaying(false);
    };

    if (audioElement) {
      audioElement.addEventListener('ended', handlePlaybackEnded);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handlePlaybackEnded);
      }
    };
  }, [])
  const togglePlay = () => {
    const audioElement = audioRef.current;

    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      if (UpdatedProps.alram) {
        setIsPlaying(!isPlaying);
      }

    }
  };
  return (
    <CenteredSquare style={UpdatedProps.style} onClick={UpdatedProps.onClick}>
      <TableContainer>
        <LeftChair />
        <RightChair />

        {
          [1, 2, 3, 4, 5].map((n) => {
            return (
              <TopChair style={{ left: `${n * 15}%` }} />
            )
          })
        }
        {
          [1, 2, 3, 4, 5].map((n) => {
            return (
              <BottomChair style={{ left: `${n * 15}%` }} />
            )
          })
        }
        <TableNumber style={{ background: UpdatedProps.color }}>{UpdatedProps.tableName}</TableNumber>
        {
            UpdatedProps.alram.map((data) => data === UpdatedProps.tableName ? (
              <>
                <audio
                  style={{ display: 'none' }}
                  ref={audioRef}
                  controls
                  autoPlay={isPlaying}
                >
                  <source src={`${process.env.REACT_APP_BASE_URL}/call_employee.mp3`} type="audio/mpeg" />
                </audio>
                <ReportGmailerrorredIcon sx={{ position: 'absolute', top: '0', right: '0', color: '#EA4F3B' }} />
              </>) : (<div></div>))
          }
      </TableContainer>
    </CenteredSquare>
  )
}
