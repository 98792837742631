import { Axios } from '../shared/Axios';
import qs from 'qs';

class ManageTableService extends Axios {

    private static instance: ManageTableService;

    static get(): ManageTableService {
        if (!ManageTableService.instance) {
            ManageTableService.instance = new ManageTableService();
        }
        return ManageTableService.instance;
    }

    async getData(): Promise<any> {
        return this.instance.get(`/manage-table`)
    }

    async saveData(data: object): Promise<any> {
        return this.instance.post(`/manage-table`, data)
    }

    async deleteData(id: string): Promise<any> {
        return this.instance.delete(`/manage-table/${id}`)
    }

    async updateData(id: string, data: object): Promise<any> {
        return this.instance.patch(`/manage-table/${id}`, data)
    } 

    async moveData(id: string, idMove: string): Promise<any> {
        return this.instance.get(`/manage-table/move/${id}/${idMove}`)
    } 
}


const manageTableService = ManageTableService.get();

export { manageTableService as ManageTableService }