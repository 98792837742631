import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PrintIcon from "@mui/icons-material/Print";
import axios from "axios";

export default function Sales() {
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedReceipt, setSelecteReceipt] = useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [listSales, setListSales] = useState([] as any);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
  };

  const handleReceipt = (event: ChangeEvent<HTMLInputElement>) => {
    setSelecteReceipt(event.target.value);
  };

  const handleEmployee = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedEmployee(event.target.value);
  };

  const getListSales = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/sales`)
      .then((response) => {
        setListSales(response.data.response);
      });
  };

  useEffect(() => {
    getListSales();
  }, []);

  return (
    <Grid container sx={{ flexGrow: 1, width: "100%" }}>
      <Grid
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={12}
        sx={{
          bgcolor: "#FFB802",
          width: "100%",
          mb: 2,
          mt: 0,
          ml: { xs: 0, md: 0 },
          p: 3,
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายงานยอดขาย
        </Typography>
      </Grid>

      <Paper
        sx={{
          width: { xs: "95%", md: "98%" },
          p: 10,
          ml: { xs: 1, md: 2 },
          mt: -1,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: { xs: 0, md: -9 }, ml: { xs: 0, md: -8 } }}
          >
            <Typography>เลขที่ใบเสร็จ</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedReceipt}
              onChange={handleReceipt}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: { xs: 0, md: -10.5 }, ml: { xs: 0, md: 33 } }}
          >
            <Typography>พนักงาน</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedEmployee}
              onChange={handleEmployee}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: { xs: 0, md: -10.5 }, ml: { xs: 0, md: 72 } }}
          >
            <Typography>วันที่</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "143px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>12/01/2024</span>
                  <CalendarMonthOutlinedIcon
                    sx={{ ml: 1, color: "action.active" }}
                  />
                </Box>
              }
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: { xs: 0, md: -10.5 }, ml: { xs: 0, md: 93 } }}
          >
            <Typography>ถึง</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "143px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>12/01/2024</span>
                  <CalendarMonthOutlinedIcon
                    sx={{ ml: 1, color: "action.active" }}
                  />
                </Box>
              }
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{ mt: { xs: 0, md: -6.7 }, ml: { xs: 0, md: 115 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#FF9B28",
                color: "#FFFFFF",
                padding: "9px 10px 9px 10px",
              }}
              sx={{
                width: { xs: "100%", md: "151px" },
                height: "42px",
                fontSize: "15px",
              }}
            >
              ค้นหา
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            sx={{ mt: { xs: 0, md: 1 }, ml: { xs: 0, md: -8 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#38DF67",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "219px" },
                height: "43px",
                fontSize: "15px",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              ดาวโหลดข้อมูลไฟล์ Excel
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            sx={{ mt: { xs: 0, md: 1 }, ml: { xs: 0, md: -5 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#4DBCFA",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "92px" },
                height: "43px",
                fontSize: "15px",
              }}
            >
              <IconButton color="inherit" size="medium">
                <PrintIcon />
              </IconButton>
              พิมพ์
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{ mt: { xs: 2, md: 5 }, ml: { xs: 0, md: -8 } }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "25px",
            }}
          >
            สาขา ............... <br />
            ระยะเวลา : 07/01/2024-12/01/2024 <br />
            จำนวนครั้ง: 8 ครั้ง <br />
            จำนวนรายการ: 14 รายการ <br />
            รายรับทั้งหมด: 1,250.00 บาท <br />
          </Typography>
        </Grid>
      </Paper>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: 2, overflowX: "auto", marginLeft: { xs: 1, sm: 2, md: 2 } }}
      >
        <Table
          sx={{
            borderRadius: "8px",
            width: { xs: "100%", sm: "100%", md: "99%" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderRadius: "8px 0px 0px 0px",
                }}
              >
                วันที่/เวลา
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                เลขที่ใบเสร็จ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                ลูกค้า
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                ชำระเงิน
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                รายการ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                จำนวนเงิน
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                ยอดรวมส่วนลดคะแนน
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderRadius: "0px 8px 0px 0px",
                }}
              >
                จำนวนเงินสุทธิ
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ sm: { flexDirection: "column" } }}>
            {listSales?.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={isMobile ? { flexDirection: "column" } : {}}
              >
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.date}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.number}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.member}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.cost}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.item}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.amount}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.discount}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
