import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

const ScanQrcode = () => {
    const cookies = new Cookies(null, { path: '/' });
    const params = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState(0);
    const [statusMessage, setStatusMessage] = useState("กำลังโหลดข้อมูล...");

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/token/check/`, {
            headers: {
                authorization: params.token
            }
        }).then((response) => {
            if(response.data.message === 'Token is vailded')
            {
                cookies.set('table_name', response.data.data.name);
                cookies.set('token', params.token);
                setStatus(1);
                setStatusMessage("กำลังพาท่านไปยังหน้าสั่งอาหาร...");
                setTimeout(() => {
                    navigate('/order');
                }, 3000);
            }
            else
            {
                setStatusMessage("URL นี้ใช้งานไม่ได้แล้ว");
            }
        });
    }, []);
    
    return (
        <>
            {
                statusMessage
            }
        </>
    )
}

export default ScanQrcode;