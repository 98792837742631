import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import FaceData from "./faceCostomer.json";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { ReportService } from "../../services/ReportService";
import { ImageSearch, Pageview, PanoramaFishEye } from "@mui/icons-material";
import Swal from "sweetalert2";
import { OrderService } from "../../services/OrderService";

const ListOfProductOrders = ({
  allProductOrders,
  getOrderProducts,
}: {
  allProductOrders: any[];
  getOrderProducts: (data: any) => Promise<void>;
}) => {
  return (
    <>
      <Typography mt={4} pb={1} variant={"h6"} style={{ fontWeight: "bold" }}>
        รายการอาหาร
      </Typography>
      <List
        sx={{
          width: "100%",
          overflow: "auto",
          height: allProductOrders.length ? 400 : "auto",
        }}
      >
        {allProductOrders.map((item: any, index: number) => {
          return (
            <>
              <ListItem alignItems="flex-start" key={index}>
                <ListItemAvatar style={{ paddingRight: 10 }}>
                  <Box
                    component="img"
                    sx={{
                      height: 80,
                      width: 80,
                      maxHeight: { xs: 80, md: 167 },
                      maxWidth: { xs: 80, md: 250 },
                      borderRadius: 3,
                    }}
                    src={item.imageUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.product_name}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        ราคา
                      </Typography>
                      <div
                        style={{
                          color: "orange",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        ฿{item.price} x {item.amount}
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          );
        })}
      </List>
    </>
  );
};

function ManagementSell() {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(!open);
  const [allProductOrders, setAllProductOrders] = useState([]);
  const [data, setData] = React.useState<Array<any>>([]);
  const [search, setSearch] = React.useState<string>("");
  
  const columns: GridColDef[] = [
    {
      field: "bill_no",
      headerName: "เลขที่ขาย",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "table_name",
      headerName: "โต๊ะ",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "bill_qty",
      headerName: "จำนวน (รายการ)",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "price",
      headerName: "ยอดบิล",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "discount",
      headerName: "ส่วนลด / คะแนน",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "เงินสุทธิ",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "amount_get",
      headerName: "รับเงิน",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "amount_change",
      headerName: "เงินทอน",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
    {
      field: "id",
      headerName: " ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return (
          <>
            <Button onClick={() => getOrderProducts(row.row)}>
              <PanoramaFishEye />
            </Button>
          </>
        );
      },
    },
  ];

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = {
        search: search,
      }; 
    const res = await ReportService.getReportBill(data);
    if (res?.status == 200) {
      const { data } = res.response;
      console.log(JSON.stringify(data));
      setData(data);
    }
  };

  const inputremovepading = {
    style: {
      padding: 5,
    },
  };

  const getOrderProducts = async (allOrders: any) => {
    setAllProductOrders(JSON.parse(allOrders.list ?? []));
    setOpen(true);
  };

  return (
    <Grid container>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายการขาย
        </Typography>
      </Grid>
      <Card sx={{ width: "100%", m: "6px" }}>
        {/* <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <Typography variant="h6">ค้นหา</Typography>
          <Button variant="contained" sx={{ bgcolor: "#38df67" }}>
            ดาวโหลดข้อมูลไฟล์ Exel
          </Button>
        </Grid> */}
        <Grid container p={1.5}>
        <Box>
        <Typography>ค้นหา</Typography>
        <TextField
          sx={{
            width: { xs: "100%", md: "291px" },
            height: "42px",
            borderRadius: "8px",
            mb: { xs: 2, md: 0 },
          }}
          value={search}
          variant="outlined"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />

        <IconButton
          style={{
            backgroundColor: "#f8f9fd",
            width: "40px",
            height: "40px",
            borderRadius: 7,
            left: "10px",
          }}
          onClick={() => getData()}
        >
          <ImageSearch style={{ color: "#8e3dfd" }} />
        </IconButton>

        {/* <Button
          onClick={() => addData()}
          variant="contained"
          style={{ background: "#13ad13", color: "white" }}
        >
           
        </Button> */}
      </Box>
          {/* <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
            <Typography variant="subtitle1">เลขที่ขาย</Typography>
            <TextField inputProps={inputremovepading} variant="outlined" />
            
          </Grid>
          <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
          <Typography sx={{ visibility: "hidden" }} variant='subtitle1'>.</Typography>
            <Button onClick={() => getData()}>
               ค้นหา
            </Button>
           </Grid> */}
          {/* <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>รหัสสินค้า</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>ประเภทการขาย</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid> */}
        </Grid>
        {/* <Grid container p={1.5} >
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>วันที่ทำการขาย</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>ลูกค้า</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} container justifyContent='space-around' >
                        <Grid xs={10} sm={3} lg={3} sx={{ m: 1 }}>
                            <Typography variant='subtitle1'>สถานะ</Typography>
                            <TextField placeholder="ทั้งหมด" inputProps={inputremovepading} variant='outlined' />
                        </Grid>
                        <Grid xs={10} sm={5} lg={3} sx={{ m: 1 }}>
                            <Typography variant='subtitle1'>ประเภทการชำระเงิน</Typography>
                            <TextField placeholder="ทั้งหมด" inputProps={inputremovepading} variant='outlined' />
                        </Grid>
                        <Grid xs={10} sm={2} lg={3} sx={{ m: 1 }}>
                            <Typography sx={{ visibility: "hidden" }} variant='subtitle1'>.</Typography>
                            <Button variant='contained' sx={{ bgcolor: "#ff9b28", width: "100%" }}>ค้นหา</Button>
                        </Grid>
                    </Grid>
                </Grid> */}
      </Card>
      <Card sx={{ width: "100%", m: "6px" }}>
        {/* <TableContainer component={Paper} sx={{ minHeight: 320 }}>
                    <Table sx={{ m: "6px", minWidth: 700 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#e5e5e5" }}>
                                <TableCell align="center">เลขที่ขาย</TableCell>
                                <TableCell align="center">ลูกค้า</TableCell>
                                <TableCell align="center">จำนวน (รายการ)</TableCell>
                                <TableCell align="center">การชำระ</TableCell>
                                <TableCell align="center">จำนวนเงิน</TableCell>
                                <TableCell align="center">ส่วนลด / คะแนน</TableCell>
                                <TableCell align="center">เงินสุทธิ</TableCell>
                                <TableCell align="center">รับเงิน</TableCell>
                                <TableCell align="center">เงินทอน</TableCell>
                                <TableCell align="center">จัดการ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                FaceData.map((data) => (
                                    <TableRow>
                                        <TableCell align="center">{data.NumberID}</TableCell>
                                        <TableCell align="center">{data.Costomer}</TableCell>
                                        <TableCell align="center">{data.Count}</TableCell>
                                        <TableCell align="center">{data.Patment}</TableCell>
                                        <TableCell align="center">{data.Price}</TableCell>
                                        <TableCell align="center">{data.Discout}</TableCell>
                                        <TableCell align="center">{data.Price - data.Discout}</TableCell>
                                        <TableCell align="center">{data.Money}</TableCell>
                                        <TableCell align="center">{data.Money - (data.Price - data.Discout)}</TableCell>
                                        <TableCell align="center">
                                            <Button variant='contained' sx={{ bgcolor: "#4dbcfa", borderRadius: "16px" }}>
                                                ดูลายละเอียด
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer> */}

        <Box
          sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
          style={{ overflow: "auto" }}
        >
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 50, 100, 500]}
            sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
            getRowId={(row: GridRowModel) => row.id}
            getRowHeight={() => "auto"}
            disableEval
            showCellVerticalBorder={true}
            disableColumnMenu
            disableColumnFilter
            rows={data}
            columns={columns}
          />
        </Box>
      </Card>

      <Modal
        open={open}
        onClose={handleOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper style={{ padding: 20, width: "80%" }}>
          <Typography variant="h6" fontWeight={"bold"}>
            รายการขาย
          </Typography>
          <ListOfProductOrders
            allProductOrders={allProductOrders}
            getOrderProducts={getOrderProducts}
          ></ListOfProductOrders>
          <Grid container justifyContent="center" sx={{ p: 3 }}>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{ borderRadius: "16px", bgcolor: "#FF7020", color: "white" }}
              size="large"
            >
              ปิด
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </Grid>
  );
}

export default ManagementSell;
