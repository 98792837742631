import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Order from "../../models/order";
import { useState } from "react";
import OrderDetailModal from "../Modal/OrderDetailModal";

interface OrderItemProp {
  orderItem: Order;
  onOrderChange: (count: number) => void;
}

export default function OrderItemCard(props: OrderItemProp) {
  const { orderItem, onOrderChange } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CardActionArea
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
          overflow: "hidden",
        }}
        onClick={() => onOpenModal()}
      >
        <CardMedia
          component="img"
          height="140"
          image={`${orderItem.imageUrl}`}
        />
        <CardContent>
          <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 500 }}>
            {orderItem.title}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 700 }} color="primary">
            ฿ {orderItem.price}
          </Typography>
        </CardContent>
      </CardActionArea>
      <OrderDetailModal
        orderItem={orderItem}
        open={isModalOpen}
        onClose={onCloseModal}
        onOrderChange={onOrderChange}
      />
    </>
  );
}
