import { Axios } from './../shared/Axios';
import qs from 'qs';
import FileSaver from 'file-saver';
import moment from 'moment';


class StockService extends Axios {

    private static instance: StockService;

    static get(): StockService {
        if (!StockService.instance) {
            StockService.instance = new StockService();
        }
        return StockService.instance;
    }

    async getStocks(data: object = {}): Promise<any> {
        return this.instance.get(`/stocks`)
    }

    async saveStock(data: object): Promise<any> {
        return this.instance.post(`/stocks`, data)
    }

    async deleteStock(stockId: string): Promise<any> {
        return this.instance.delete(`/stocks/${stockId}`)
    }

    async downloadStock(): Promise<any> {
        return this.instance.post(`/stocks/export`, {}, {responseType: 'arraybuffer'}).then(res => {
            console.log(res?.data)
            var blob = new Blob([res?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            FileSaver.saveAs(blob, `report_${moment().format('DDMMMYYYYHHmm')}.xlsx`)
            return res?.data;
        })


    }


}


const stockService = StockService.get();

export { stockService as StockService }