import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ConvertUtcToBuddhistFormat = (date: string) => {
  dayjs.extend(utc);
  dayjs.extend(buddhistEra);

  const startsOn = dayjs(date);
  return startsOn.format("DD/MM/BBBB HH:mm");
};

export const ConvertGMTToYYYYMMDDFormat = (date: string) => {
  const startsOn = dayjs(date);

  return startsOn.format("YYYY-MM-DD");
};
