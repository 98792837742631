import { Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import FaceData from './faceCostomer.json'
import DeleteIcon from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
function ReturnProduct() {
    const inputremovepading = {
        style: {
            padding: 5
        }
    }
    return (
        <Grid container>
            <Grid sm={12} sx={{ bgcolor: '#FFB802', p: 3,width:'100%' }}>
                <Typography
                    sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}
                >
                    คืนรายการสินค้า
                </Typography>
            </Grid>
            <Card sx={{ width: "100%", m: "6px" }}>
                <Grid container p={1.5}>
                    <Grid xs={10} sm={3} lg={2.5} sx={{
                        m: 1
                    }}>
                        <Typography variant='subtitle1' sx={{
                            '@media (max-width: 910px)': {
                                fontSize: '12px'
                            },
                        }}>รหัสสมาชิก/ชื่อ-นามสกุล</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1' sx={{
                            '@media (max-width: 910px)': {
                                fontSize: '12px'
                            },
                        }}>เลขผุ้เสียภาษี</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1' sx={{
                            '@media (max-width: 910px)': {
                                fontSize: '12px'
                            },
                        }}>เบอร์โทร</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid container alignItems='center' xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Grid sm={9}>
                            <Button sx={{
                                fontSize: '10px',
                                bgcolor:'#ff000094',
                                '@media (max-width: 770px)': {
                                    width: '120px'
                                },
                            }} variant='contained'>รีเซ็ทคะแนนสะสม</Button>
                        </Grid>
                        <Grid sm={3}>
                            <Button sx={{
                                fontSize: '10px',
                                bgcolor: 'green',
                                '@media (max-width: 770px)': {
                                    ml: '32px'
                                },
                            }} variant='contained'>เพิ่ม</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Card>
            <Card sx={{ width: "100%", m: "6px" }}>
                <TableContainer component={Paper} sx={{ minHeight: 320 }}>
                    <Table sx={{ m: "6px", minWidth: 700 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#e5e5e5" }}>
                                <TableCell align="center">ลำดับ</TableCell>
                                <TableCell align="center">เลขสมาชิก</TableCell>
                                <TableCell align="center">ชื่อ</TableCell>
                                <TableCell align="center">ที่อยู่</TableCell>
                                <TableCell align="center">ข้อมูลการติดต่อ</TableCell>
                                <TableCell align="center">โน๊ต</TableCell>
                                <TableCell align="center">จัดการ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                FaceData.map((data) => (
                                    <TableRow>
                                        <TableCell align="center">{data.NumberID}</TableCell>
                                        <TableCell align="center">{data.Costomer}</TableCell>
                                        <TableCell align="center">{data.Count}</TableCell>
                                        <TableCell align="center">{data.Patment}</TableCell>
                                        <TableCell align="center">{data.Price}</TableCell>
                                        <TableCell align="center">{data.Money - (data.Price - data.Discout)}</TableCell>
                                        <TableCell align="center">
                                            <FeedIcon sx={{ color: '#ffb80291' }} />
                                            <DeleteIcon sx={{color:'#ff000094'}}/>

                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>

    )
}

export default ReturnProduct