import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Order from "../../models/order";
import { isEmpty } from "lodash";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import OrderDetailModal from "../Modal/OrderDetailModal";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
const socket = io(`${process.env.REACT_APP_API_URL}`, {
  withCredentials: true,
});
interface OrderItemCardProp {
  orderItems: Order[];
  onOrderChange: (count: number) => void;
}

export default function OrderCart(props: OrderItemCardProp) {
  const { orderItems: orderItemsCart, onOrderChange } = props;
  const totalPrice = orderItemsCart.reduce((accumulator, object) => {
    return accumulator + object.price * object.amount!;
  }, 0);
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editOrderItem, setEditOrderItem] = useState<Order | null>(null);

  const sendOrder = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/routes/order`, orderItemsCart, {
        headers: {
          authorization: cookies.get("token"),
        },
      })
      .then((response: any) => {
        if (response.data.message == "OK") {
          localStorage.removeItem("orders");
          window.location.reload();
        }
      });
    socket.emit("routes", 1);
  };

  const onCloseModal = () => {
    setEditOrderItem(null);
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (editOrderItem) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [editOrderItem]);

  return (
    <>
      {!isEmpty(orderItemsCart) ? (
        <>
          <Stack sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2, flexGrow: 1 }}>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 500 }}>
                  ตะกร้าอาหาร
                </Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 500 }}>
                  โต๊ะ {cookies.get("table_name")}
                </Typography>
              </Stack>
              <List>
                {orderItemsCart.map((orderItemCart) => (
                  <>
                    <ListItem disablePadding>
                      <Stack
                        key={orderItemCart.id}
                        direction="row"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <IconButton
                            sx={{
                              border: 1,
                              borderColor: "secondary.light",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              mr: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "secondary.main" }}
                            >
                              x{orderItemCart.amount}
                            </Typography>
                          </IconButton>
                        </Box>
                        <List sx={{ flexGrow: 1, p: 0 }}>
                          <ListItemText
                            primary={orderItemCart.title}
                            // secondary={JSON.stringify(orderItemCart.option)}
                          />
                          {orderItemCart.option?.map((option: any) => {
                            return (
                              <ListItemText
                                secondary={`- (${Object.keys(option)[0]}) ${
                                  option[Object.keys(option)[0]]
                                }`}
                              />
                            );
                          })}
                          <ListItemText>
                            <Button
                              sx={{ p: 0, justifyContent: "start" }}
                              variant="text"
                              onClick={() => setEditOrderItem(orderItemCart)}
                            >
                              <Typography color="red" sx={{ fontSize: 10 }}>
                                แก้ไข
                              </Typography>
                            </Button>
                          </ListItemText>
                          {/* <ul>{orderItemCart.option.map(item => <li key=`${Date.now()}`> {item} </li>)}</ul> */}
                        </List>
                        <Box>
                          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                            ฿{" "}
                            {orderItemCart.price * Number(orderItemCart.amount)}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItem>
                    <Divider sx={{ my: 2 }} />
                  </>
                ))}
              </List>
            </Box>
            <Box sx={{ p: 4, flexGrow: 0 }}>
              <Stack direction="row" sx={{ alignItems: "baseline" }}>
                <Typography
                  color="secondary.light"
                  sx={{ mr: 2, fontSize: "14px" }}
                >
                  รวมทั้งหมด
                </Typography>
                <Typography
                  color="secondary"
                  sx={{ fontSize: "20px", fontWeight: 700 }}
                >
                  ฿ {totalPrice}
                </Typography>
              </Stack>
              <Button
                onClick={sendOrder}
                sx={{
                  height: "60px",
                  width: "100%",
                  flexGrow: 1,
                  display: "flex",
                  color: "white",
                }}
                variant="contained"
              >
                สั่งอาหาร
              </Button>
            </Box>
          </Stack>
          {editOrderItem && (
            <OrderDetailModal
              onClose={onCloseModal}
              onOrderChange={onOrderChange}
              open={isOpenModal}
              orderItem={editOrderItem}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
