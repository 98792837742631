import {
  LocalGroceryStoreOutlined,
  MonetizationOnOutlined,
  OutlinedFlagOutlined,
  StorageOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import Section from "../../components/Dashboard/Section";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import ItemList from "../../components/Dashboard/ItemList";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import DbIcon from "../../assets/images/db.png";
import MoneyIcon from "../../assets/images/money.png";
import CartIcon from "../../assets/images/cart.png";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
moment.locale("th");

const columns: GridColDef[] = [
  {
    field: "productName",
    headerName: "รายการสินค้า",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "totalStock",
    headerName: "สต๊อคสินค้า",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "2566",
      data: [0, 10000, 20000, 40000, 50000, 2000, 3000, 4000, 50000],
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "2565",
      data: [0, 10000, 20000, 40000, 50000],
      backgroundColor: "blue",
    },
    {
      label: "2564",
      data: [0, 10000, 20000, 40000, 50000],
      backgroundColor: "green",
    },
    {
      label: "2563",
      data: [0, 10000, 20000, 40000, 50000],
      backgroundColor: "orange",
    },
  ],
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "5px 10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface Product {
    productName: string
    productPrice: string
    productImg: string
}

interface Summary {
    all: {
        income: number
        expenses: number
        profitAndLoss: number
    },
    today: {
        income: number
        customers: number
        numberOfItemsSold: number
    },
    stock: any,
    bestsellingProductsOfThePast7days: any
}

export default function Dashboard() {
  const [allSummary, setAllSummary] = React.useState<Summary>({
    all: {
      income: 0,
      expenses: 0,
      profitAndLoss: 0,
    },
    today: {
      income: 0,
      customers: 0,
      numberOfItemsSold: 0,
    },
    stock: [],
    bestsellingProductsOfThePast7days: [],
  });
  const [listOfBuy, setListOfBuy] = React.useState<Product[]>([]);
  const [totalPrice, setTotalPrice] = React.useState<string>("0");
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    const d = [
      {
        productName: "ส้มตำ",
        productPrice: "25.12",
        productImg:
          "https://image.posttoday.com/media/content/2018/10/09/7E68135B4E03435881B219B331FB9169.jpg?x-image-process=style/lg",
      },
      {
        productName: "ลาบ",
        productPrice: "2.12",
        productImg:
          "https://image.posttoday.com/media/content/2018/10/09/7E68135B4E03435881B219B331FB9169.jpg?x-image-process=style/lg",
      },
    ];
    setListOfBuy(d);

    setAllSummary({
      all: {
        income: 14928,
        expenses: 14928,
        profitAndLoss: 14928,
      },
      today: {
        income: 1250,
        customers: 35,
        numberOfItemsSold: 35,
      },
      stock: [
        {
          productName: "มะละกอ",
          totalStock: "20 Kg.",
        },
      ],
      bestsellingProductsOfThePast7days: [
        {
          productName: "ส้มตำไทย",
          totalAmount: 20,
        },
        {
          productName: "ส้มตำปูไทย",
          totalAmount: 3,
        },
        {
          productName: "ลาบ",
          totalAmount: 20,
        },
        {
          productName: "ไก่ทอด",
          totalAmount: 1,
        },
      ],
    });

    const totalPrice = _.sum(
      d.map((item) => parseFloat(`${item.productPrice}`))
    );
    setTotalPrice(
      totalPrice.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ bgcolor: "white" }}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container p={3} md={12} lg={12}>
          <div>อัพเดตล่าสุด 12/01/2024</div>
        </Grid>
        <Grid container p={3} spacing={2} pb={3} md={12} lg={12}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Section
              title="รายรับ"
              total={`${allSummary.all.income.toLocaleString("en-US")}`}
              icon={<img src={MoneyIcon} />}
              bottomTitle={"+ 10% of target"}
            ></Section>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Section
              title={<div style={{ color: "rgb(255 84 84)" }}>รายจ่าย</div>}
              total={`${allSummary.all.expenses.toLocaleString("en-US")}`}
              icon={<img src={CartIcon} />}
              bottomTitle={` `}
            ></Section>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Section
              title={
                <div style={{ color: "rgb(121 196 71)" }}>กำไร/ ขาดทุน</div>
              }
              total={`${allSummary.all.profitAndLoss.toLocaleString("en-US")}`}
              icon={<img src={DbIcon} />}
              bottomTitle={"+ 10% of target"}
            ></Section>
          </Grid>
        </Grid>

        <Grid container pb={3} md={12} lg={12}>
          <Grid
            item
            md={8}
            lg={8}
            p={2}
            borderTop={"1px solid rgb(231 231 231)"}
          >
            <Typography mt={1} variant="h5" style={{ fontWeight: "bold" }}>
              ยอดขายวันนี้
            </Typography>
            <div style={{ color: "#ccc" }}>
              {moment().format(
                `วันddddที่ D เดือน MMMM พศ. ${moment()
                  .add(543, "year")
                  .format("YYYY")}`
              )}
            </div>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Paper
                  elevation={0}
                  style={{
                    border: "1px solid rgb(236 236 236)",
                    padding: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={MoneyIcon}
                    style={{ maxWidth: 55, paddingBottom: 10 }}
                  />
                  <div style={{ fontSize: 26 }}>
                    <div>
                      {allSummary.today.income.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </div>
                    <div style={{ fontSize: 12, textAlign: "left" }}>
                      รายได้
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Paper
                  elevation={0}
                  style={{
                    border: "1px solid rgb(236 236 236)",
                    padding: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={CartIcon}
                    style={{ maxWidth: 55, paddingBottom: 10 }}
                  />
                  <div style={{ fontSize: 26 }}>
                    <div>{allSummary.today.customers}</div>
                    <div style={{ fontSize: 12, textAlign: "left" }}>
                      จำนวนลูกค้า
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Paper
                  elevation={0}
                  style={{
                    border: "1px solid rgb(236 236 236)",
                    padding: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={DbIcon}
                    style={{ maxWidth: 55, paddingBottom: 10 }}
                  />
                  <div style={{ fontSize: 26 }}>
                    <div>{allSummary.today.numberOfItemsSold}</div>
                    <div style={{ fontSize: 12, textAlign: "left" }}>
                      จำนวนรายการที่ขาย
                    </div>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  mt={2}
                  pb={2}
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                >
                  สินค้าใกล้หมด
                </Typography>
                <DataGrid
                  style={{ height: "auto" }}
                  getRowId={(row: GridRowModel) => row.productName}
                  // getRowHeight={() => 'auto'}
                  showColumnVerticalBorder={true}
                  disableEval
                  showCellVerticalBorder={true}
                  disableColumnMenu
                  disableColumnFilter
                  rows={allSummary.stock}
                  columns={columns}
                  hideFooter
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            p={4}
            style={{ border: "1px solid rgb(231 231 231)" }}
          >
            <Typography pb={2} variant="h6" style={{ fontWeight: "400" }}>
              สินค้าขายดี 7 วันย้อนหลัง
            </Typography>
            {allSummary.bestsellingProductsOfThePast7days.map(
              (item: any, index: number) => {
                return (
                  <ItemList
                    key={index}
                    titleLeft={item.productName}
                    titleRight={`จำนวน ${item.totalAmount}`}
                    isProgressBar
                  ></ItemList>
                );
              }
            )}
          </Grid>
          <Grid item p={3} xs={12} sm={12} md={12} lg={12}>
            <Typography
              mt={4}
              pb={2}
              variant="h5"
              style={{ fontWeight: "bold" }}
            >
              กราฟแสดงยอดขายต่อเดือน
            </Typography>
            <Bar options={options} data={data} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} borderLeft={"1px solid #ccc"}>
        <Grid container p={2} xs={12} sm={12} md={12} lg={12}>
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <FormControl sx={{ mt: 2, pr: 1 }}>
              <Button
                fullWidth
                size={"large"}
                style={{
                  backgroundColor: "#7b69e8",
                  color: "white",
                  borderRadius: 10,
                }}
              >
                ย้ายโต๊ะ / รวมโต๊ะ
              </Button>
            </FormControl>

            <FormControl
              sx={{ mt: 2, pr: 1 }}
              component={Link}
              to={"/orderkitchen"}
            >
              <Button
                fullWidth
                size={"large"}
                style={{
                  backgroundColor: "#e2e2e2",
                  color: "black",
                  borderRadius: 10,
                }}
              >
                ส่งไปยังครัว
              </Button>
            </FormControl>
          </Box>
          <Box p={2} sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                sx={{
                  borderRadius: 20,
                  borderColor: "divider",
                  background: "#f8f9fd",
                }}
              >
                <TabList onChange={handleChange}>
                  <Tab
                    sx={{
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    style={{
                      background: "white",
                      borderRadius: 25,
                      margin: 10,
                    }}
                    label="ซื้อ"
                    value="1"
                  />
                  <Tab label="จองโต๊ะ" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Typography
                  mt={4}
                  pb={1}
                  variant={"subtitle1"}
                  style={{ fontWeight: "bold" }}
                >
                  รายการอาหาร
                </Typography>
                <List sx={{ width: "100%" }}>
                  {listOfBuy.map((item, index) => {
                    return (
                      <ListItem alignItems="flex-start" key={index}>
                        <ListItemAvatar style={{ paddingRight: 10 }}>
                          <Box
                            component="img"
                            sx={{
                              height: 80,
                              width: 80,
                              maxHeight: { xs: 80, md: 167 },
                              maxWidth: { xs: 80, md: 250 },
                              borderRadius: 3,
                            }}
                            src={item.productImg}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.productName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                ราคา
                              </Typography>
                              <div
                                style={{ color: "orange", fontWeight: "bold" }}
                              >
                                {" "}
                                ฿{item.productPrice}
                              </div>
                            </React.Fragment>
                          }
                        />
                        <Divider></Divider>
                      </ListItem>
                    );
                  })}
                </List>
              </TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
            </TabContext>

            <Box
              textAlign={"right"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"end"}
            >
              <div>รวม {listOfBuy.length} รายการ</div>
              <br></br> <br></br>
              <div style={{ fontSize: 14 }}>ส่วนลดท้ายบิล</div>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
                <BootstrapInput
                  placeholder="ส่วนลด (บาท)"
                  defaultValue=""
                  id="bootstrap-input"
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
                <BootstrapInput
                  placeholder="0.00"
                  defaultValue=""
                  id="bootstrap-input"
                />
              </FormControl>
              <br></br>
              <br></br>
              <div>ยอดชำระทั้งหมด</div>
              <div style={{ fontSize: 27 }}>{totalPrice} บาท</div>
            </Box>

            <Button
              fullWidth
              size={"large"}
              style={{
                backgroundColor: "#62a433",
                color: "white",
                borderRadius: 25,
                padding: "10px 35px 10px 35px",
              }}
            >
              เช็คบิล
            </Button>
            <br></br>
            <br></br>

            <Button
              fullWidth
              size={"large"}
              style={{
                backgroundColor: "#ff6801",
                color: "white",
                borderRadius: 25,
                padding: "10px 35px 10px 35px",
              }}
            >
              ชำระเงิน
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
