import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  CloudUploadOutlined,
  DeleteOutline,
  PrivacyTipOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { FoodService } from "../../services/FoodService";
import Swal from "sweetalert2";
import { ManageTableService } from "../../services/ManageTableService";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 19,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface Props {
  editRecord: {
    id: string;
    name: string;
    seats: number;
    isActive: boolean
  };
  onClose: () => void;
  onResponse: (data: any, isUpdate: boolean) => void;
}

export default function Create(props: Props) {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tableName: props.editRecord?.name,
      qtyTable: props.editRecord?.seats,
      isActive: props.editRecord?.isActive,
    },
  });

  const onSubmit = async (data: any) => {
    if (props.editRecord?.id) {
      return onUpdate(data);
    }
    const res = await ManageTableService.saveData(data);
    if (res?.status == 200) {
      const { data } = res.response;
      props.onResponse(data, false);
      Swal.fire({
        icon: "success",
        text: res?.message,
      });
      props.onClose();
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message,
      });
    }
  };

  const onUpdate = async (data: object) => {
    const res = await ManageTableService.updateData(props.editRecord.id, data);
    if (res?.status == 200) {
      const { data } = res.response;
      props.onResponse(data, true);
      Swal.fire({
        icon: "success",
        text: res?.message,
      });
      props.onClose();
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message,
      });
    }
  };

  const label = "";
  
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
        <InputLabel shrink htmlFor="bootstrap-input">
          ชื่อโต๊ะ
        </InputLabel>
        <BootstrapInput
          {...register("tableName", { required: true })}
          defaultValue=""
          id="bootstrap-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
        <InputLabel shrink htmlFor="bootstrap-input">
          จำนวนที่นั่ง
        </InputLabel>
        <BootstrapInput
          {...register("qtyTable", { required: true })}
          defaultValue=""
          id="bootstrap-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
        <InputLabel shrink htmlFor="bootstrap-input">
          สถานะ
        </InputLabel>
        <Checkbox {...register("isActive")} 
          checked={watch("isActive")} />
      </FormControl>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Button
            onClick={() => props.onClose()}
            fullWidth
            size={"large"}
            style={{
              border: "1px solid #ff6801",
              color: "#ff6801",
              borderRadius: 25,
              padding: "10px 35px 10px 35px",
            }}
          >
            ยกเลิก
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Button
            type="submit"
            fullWidth
            size={"large"}
            style={{
              backgroundColor: "#ff6801",
              color: "white",
              borderRadius: 25,
              padding: "10px 35px 10px 35px",
            }}
          >
            {props.editRecord?.id ? "แก้ไข" : "เพิ่ม"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
