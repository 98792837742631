import React from 'react'
import { Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'

function DeliveryNote() {
    const inputremovepading = {
        style: {
            padding: 5
        }
    }
    return (
        <Grid container>
            <Grid sm={12} sx={{ bgcolor: '#FFB802', p: 3, width: '100%' }}>
                <Typography
                    sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}
                >
                    ใบส่งสินค้า
                </Typography>
            </Grid>
            <Card sx={{ width: "100%", m: "6px" }}>
                <Grid container justifyContent="space-between" sx={{ p: 2 }}>
                    <Typography variant='h6'>
                        ค้นหา
                    </Typography>
                </Grid>
                <Grid container p={1.5}>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>เลขที่เอกสาร</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>ลูกค้า</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>วันที่ทำรายการ</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={1} lg={3} sx={{ m: 1 }}>
                        <Typography sx={{ visibility: "hidden" }} variant='subtitle1'>.</Typography>
                        <Button variant='contained' sx={{ bgcolor: "#ff9b28", width: "100%" }}>ค้นหา</Button>
                    </Grid>
                </Grid>
            </Card>
            <Card sx={{ width: "100%", m: "6px" }}>
                <TableContainer component={Paper} sx={{ minHeight: 320 }}>
                    <Table sx={{ m: "6px", minWidth: 700 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#e5e5e5" }}>
                                <TableCell align="center">เลขที่</TableCell>
                                <TableCell align="center">วันที่ออก</TableCell>
                                <TableCell align="center">อ้างอิงเลขการขาย</TableCell>
                                <TableCell align="center">ลูกค้า</TableCell>
                                <TableCell align="center">จำนวนเงิน</TableCell>
                                <TableCell align="center">จัดการ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                    <Button variant='contained' sx={{ bgcolor: "#4dbcfa", borderRadius: "16px" }}>
                                        ดูลายละเอียด
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    )
}

export default DeliveryNote