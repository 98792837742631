import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  Modal,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  InputBase,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import face_Api from "./faceApi.json";
import face_Menu from "./faceApiSelectMenu.json";
import moneyIcon from "../../assets/money.png";
import qrCode from "../../assets/qr-scan.png";
import BackspaceIcon from "@mui/icons-material/Backspace";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import "./test.css";
import { alpha, styled } from "@mui/material/styles";
import { OrderService } from "../../services/OrderService";
import _ from "lodash";
import Swal from "sweetalert2";
import { GridSearchIcon } from "@mui/x-data-grid";
import {
  Add,
  AssignmentOutlined,
  AutoDeleteOutlined,
  DeleteTwoTone,
} from "@mui/icons-material";

interface ProductProps {
  productName: string;
  productPrice: string;
  productImg: string;
}

interface SelectCategoryMenuProps {
  categoryId: string;
  categoryName: string;
}

const styleBTN = {
  cursor: "pointer",
  bgcolor: "#7f7f7f1f",
  p: 1.7,
  borderRadius: "40%",
  m: "3px 6px",
};
const stylePayment = {
  position: "absolute" as "absolute",
  top: "50%",
  right: "0",
  transform: "translate(0%, -50%)",
  width: "30%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 770px)": {
    width: "50%",
  },
  "@media (max-width: 440px)": {
    width: "100%",
  },
};
 
const ListOfProductOrders = ({
  allProductOrders,
  getOrderProducts
}: {
  allProductOrders: any[];
  getOrderProducts: (tableId: string) => Promise<void>;
}) => {

    const onDeleteCart = async (dataId: string, table_id: string) => {
        try {
          Swal.fire({
            title: "Are you sure to delete ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonColor: "orange",
            confirmButtonText: "Confirm. Delete it!",
            denyButtonText: `Cancel`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await OrderService.deleteData(dataId);
              if (res?.status == 200) { 
                  getOrderProducts(table_id);
              }
            } else if (result.isDenied) {
            }
          });
        } catch (error) {
          console.error("Error saving data:", error);
          Swal.fire({
            icon: "error",
            text: "An error occurred while saving the data.", // ข้อความแสดงข้อผิดพลาดในกรณีที่เกิดข้อผิดพลาด
          });
        }
      };

  return (
    <>
      <Typography mt={4} pb={1} variant={"h6"} style={{ fontWeight: "bold" }}>
        รายการอาหาร
      </Typography>
      <List
        sx={{
          width: "100%",
          overflow: "auto",
          height: allProductOrders.length ? 400 : "auto",
        }}
      >
        {allProductOrders.map((item: any, index: number) => {
          return (
            <>
              <ListItem alignItems="flex-start" key={index}>
                <ListItemAvatar style={{ paddingRight: 10 }}>
                  <Box
                    component="img"
                    sx={{
                      height: 80,
                      width: 80,
                      maxHeight: { xs: 80, md: 167 },
                      maxWidth: { xs: 80, md: 250 },
                      borderRadius: 3,
                    }}
                    src={item.imageUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.product_name}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        ราคา
                      </Typography>
                      <div
                        style={{
                          color: "orange",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        ฿{item.price} x {item.amount}
                      </div>
                    </React.Fragment>
                  }
                />
                <IconButton
                  style={{
                    backgroundColor: "#f8f9fd",
                    width: "40px",
                    height: "40px",
                    borderRadius: 7,
                    left: "10px",
                  }}
                  onClick={() => onDeleteCart(item.orderId, item.table_id) }
                >
                  <DeleteTwoTone style={{ color: "#8e3dfd" }} />
                </IconButton>
              </ListItem>
              <Divider></Divider>
            </>
          );
        })}
      </List>
    </>
  );
};

export default function FoodMenu() {
  const [selectCategoryMenu, setSelectCategoryMenu] =
    useState<SelectCategoryMenuProps>({ categoryId: "", categoryName: "" });
  const [tables, setTables] = useState([]);
  const [tableSelected, setTableSelected] = useState<string>("");
  const [tableNameSelected, setTableNameSelected] = useState<string>("");
  const [allFoods, setAllFoods] = useState([]);
  const [allProductByCategorys, setAllProductByCategorys] = useState([]);
  const [allProductOrders, setAllProductOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [allCategorys, setAllCategorys] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [payment, setPayment] = useState<boolean>(false);
  const [getAmount, setGetAmount] = React.useState<string>("");
  const handleOpen = () => setOpen(!open);
  const [discountAmount, setDiscountAmount] = useState<string>("0");
  const [changeAmount, setChangeAmount] = useState<string>("0");
  const [grandTotalPrice, setGrandTotalPrice] = useState<string>("0");

  React.useEffect(() => {
    calculate();
  }, [getAmount, discountAmount]);

  React.useEffect(() => {
    getOrderTables();
    getFoods();
  }, []);

  React.useEffect(() => {
    if (tableSelected) {
      getOrderProducts(tableSelected);
    }
  }, [tableSelected]);

  React.useEffect(() => {
    if (selectCategoryMenu.categoryId) {
      setAllProductByCategorys(
        allFoods.filter(
          (item: any) => item.category_id == selectCategoryMenu.categoryId
        )
      );
    } else {
      setAllProductByCategorys(allFoods);
    }
  }, [selectCategoryMenu.categoryId]);

  const getOrderTables = async () => {
    const res = await OrderService.getOrderTables();
    if (res?.status == 200) {
      const { allTables } = res.response;
      setTables(allTables);
    }
  };

  const getFoods = async () => {
    const res = await OrderService.getFoods();
    if (res?.status == 200) {
      const { menus, categorys } = res.response;
      setAllFoods(menus);
      setAllProductByCategorys(menus);
      setAllCategorys(categorys);
    }
  };

  const onSearchMenu = (keyword: string) => {
    if (!keyword.trim() && !selectCategoryMenu.categoryId) {
      setAllProductByCategorys(allFoods);
      return;
    }

    setAllProductByCategorys(
      allFoods.filter((item: any) => {
        const regex = new RegExp(keyword, "gi");
        const matches = item.product_name.match(regex);
        console.log("matches", matches);
        if (!selectCategoryMenu.categoryId && matches) {
          return item;
        } else if (
          item.category_id == selectCategoryMenu.categoryId &&
          matches
        ) {
          return item;
        }
      })
    );
  };

  const getOrderProducts = async (tableId: string) => {
    const res = await OrderService.getOrderProducts(tableId);
    if (res?.status == 200) {
      const { allOrders, allCategorys, totalPrice } = res.response;
      // setAllCategorys(allCategorys)
      setAllProductOrders(allOrders);
      setTotalPrice(totalPrice);
    }
  };

  const printReceipt = async () => {
    try {
      console.log(getAmount.trim()+"/"+ discountAmount.trim(), grandTotalPrice.trim())
        const res = await OrderService.printOrderReceipt(tableSelected,
          parseFloat(getAmount || "0").toString() , parseFloat(discountAmount || "0").toString(),  parseFloat(grandTotalPrice || "0").toString()   
        );

        if (res?.status === 200) {
            const { receiptUrl } = res.response;

            // เพิ่ม timestamp เพื่อให้ URL เป็นเอกลักษณ์
            const uniqueUrl = `${receiptUrl}?timestamp=${new Date().getTime()}`;

            // เพิ่ม delay 3 วินาที ก่อนเปิด URL ใหม่ในแท็บใหม่
            setTimeout(() => {
                window.open(uniqueUrl, '_blank');
            }, 2000);  
        }
    } catch (error) {
        console.error('Error printing receipt:', error);
    }
};

  

  const savePaymentOrder = async () => {
    const res = await OrderService.savePaymentOrder({
      totalPrice: totalPrice,
      discountAmount: discountAmount, 
      amount: parseFloat(totalPrice)-((parseFloat(totalPrice) * parseFloat(discountAmount)/100)),
      amount_get: getAmount,
      amount_change: grandTotalPrice,
      tableId: tableSelected,
    });
    if (res?.status == 200) {
      Swal.fire({
        icon: "success",
        text: res?.message,
      });
      window.location.reload();
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message,
      });
    }
    //----------------------------------------------------------------------------------------------
    // console.log(allProductOrders)
    // console.log(totalPrice)
    // console.log(getAmount)
    // console.log(discountAmount)
    // เก็บ log การขาย
    //----------------------------------------------------------------------------------------------
  };

  const handlePayment = () => {
    setPayment(!payment);
    setOpen(!open);
  };
  const addAmount = (input: string) => {
    if (input === "C") {
      setGetAmount("0");
      setDiscountAmount("0");
      setChangeAmount("0");
    } else if (input === "CC") {
      const result = getAmount.substring(0, getAmount.length - 1);
      setGetAmount(result);
    } else if (input === "10%" || input === "20%") {
      // const discountPercentage = parseFloat(input) / 100;
      // const totalPriceValue = parseFloat(totalPrice);
      // const discountedPrice = totalPriceValue * (1 - discountPercentage);

      // คำนวณราคาที่ต้องจ่ายจริงตามสูตร
      // const finalPrice = totalPriceValue * (100 - discountPercentage) / 100;

      // คำนวณเงินทอนโดยตรงจากราคาที่ต้องจ่ายจริงและราคาเต็ม
      // const changeAmountValue = totalPriceValue - finalPrice;

      setDiscountAmount(input);
      // setChangeAmount(changeAmountValue.toFixed(2));
    } else if (!isNaN(parseFloat(input))) {
      setGetAmount((prevAmount) => prevAmount + input);
    }
  };

  const calculate = () => {
    let grandTotalPrice = 0 ;
    let _discountAmount = parseFloat(discountAmount);
    if (_discountAmount) {
      grandTotalPrice = parseFloat(getAmount || "0") -
      (parseFloat(totalPrice) - (parseFloat(totalPrice) * (_discountAmount / 100)));
    }else {
      grandTotalPrice = parseFloat(getAmount || "0") - parseFloat(totalPrice);
    }

    setGrandTotalPrice(
      grandTotalPrice.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  };

  const onAddCart = async (table_id: string, menu_id: string) => {
    try {
      Swal.fire({
        title: "Are you sure to add cart ?",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonColor: "orange",
        confirmButtonText: "Confirm. Add it!",
        denyButtonText: `Cancel`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await OrderService.addCartNew(table_id, menu_id);

          if (res?.status == 200) {
            // const { data } = res.response;
            //props.onResponse(data, false);
            Swal.fire({
              icon: "success",
              text: res?.message,
            });
            getOrderProducts(table_id);
          } else {
            Swal.fire({
              icon: "error",
              text: res?.message,
            });
          }
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        text: "An error occurred while saving the data.", // ข้อความแสดงข้อผิดพลาดในกรณีที่เกิดข้อผิดพลาด
      });
    }
  };

  return (
    <Grid container p={4} sx={{ bgcolor: "white" }}>
      <Modal
        open={open}
        onClose={handleOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper style={{ padding: 20, width: "80%" }}>
          <Typography variant="h6" fontWeight={"bold"}>
            ชำระเงิน
          </Typography>
          <ListOfProductOrders
            allProductOrders={allProductOrders}
            getOrderProducts={getOrderProducts}
          ></ListOfProductOrders>
          <Grid container justifyContent="center" sx={{ p: 3 }}>
            <Button
              variant="contained"
              onClick={handlePayment}
              sx={{ borderRadius: "16px", bgcolor: "#FF7020", color: "white" }}
              size="large"
            >
              ยืนยันรายการอาหาร
            </Button>
          </Grid>
        </Paper>
      </Modal>

      <Modal open={payment} onClose={handlePayment}>
        <Box sx={stylePayment}>
          <Typography variant="h6" fontWeight={"bold"}>
            ยอดการชำระเงิน
          </Typography>
          <Box display={"flex"} justifyContent={"flex-end"} alignItems="center">
            <div style={{ paddingRight: 15 }}>ยอดสุทธิ</div>
            <div style={{ fontSize: 28, fontWeight: "bold" }}>
              {parseFloat(totalPrice).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
              ฿
            </div>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} alignItems="center">
            <div style={{ paddingRight: 15 }}>รับเงิน</div>
            <div style={{ fontSize: 28, fontWeight: "bold" }}>
              {parseFloat(getAmount || "0").toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }) || "0"}{" "}
              ฿
            </div>
          </Box>

          <Box display={"flex"} justifyContent={"flex-end"} alignItems="center">
            <div style={{ paddingRight: 15 }}>ส่วนลด</div>
            <div style={{ fontSize: 28, fontWeight: "bold" }}>
              {parseFloat(discountAmount || "0").toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }) || "0"}{" "}
              %
            </div>
          </Box>
          <Divider></Divider>
          {getAmount !== "0" ? (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems="center"
            >
              <div style={{ paddingRight: 15 }}>เงินทอน</div>
              <div style={{ fontSize: 28, fontWeight: "bold" }}>
                {grandTotalPrice} ฿
              </div>
            </Box>
          ) : null}

          {/* <Grid container justifyContent='space-around' sx={{ m: '12px 0' }} >
                        <Grid xs={3} container justifyContent='center' sx={{ border: '2px solid #FFB802', p: 2, borderRadius: '12px' }}>
                            <img src={moneyIcon} alt="เงินสด" />
                        </Grid>
                        <Grid xs={3} container justifyContent='center' sx={{ border: '2px solid #FFB802', p: 2, borderRadius: '12px' }}>
                            <img src={qrCode} alt="สแกน QR CODE" />
                        </Grid>
                    </Grid> */}
          <Grid container sx={{ mt: "14px" }}>
            <Grid xs={3} onClick={() => addAmount("1")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                1
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("2")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                2
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("3")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                3
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("10%")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                10%
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} onClick={() => addAmount("4")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                4
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("5")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                5
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("6")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                6
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("20%")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                20%
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} onClick={() => addAmount("7")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                7
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("8")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                8
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("9")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                9
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("CC")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                <BackspaceIcon style={{ color: "#FF7020" }} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: "16px" }}>
            <Grid xs={3} onClick={() => addAmount("C")}>
              <Typography
                variant="h4"
                sx={styleBTN}
                style={{ color: "#FF7020" }}
                textAlign="center"
              >
                C
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount("0")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                0
              </Typography>
            </Grid>
            <Grid xs={3} onClick={() => addAmount(".")}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                .
              </Typography>
            </Grid>
            <Grid xs={3}>
              <Typography variant="h4" sx={styleBTN} textAlign="center">
                Add
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: "16px" }}>
            <Grid xs={3} item>
              <Button
                onClick={() => printReceipt()}
                style={{
                  background: "black",
                  color: "white",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <LocalPrintshopIcon style={{ marginBottom: "8px" }} />
                พิมใบเสร็จ
              </Button>
            </Grid>
            <Grid xs={3}>
              <Button
                style={{
                  background: "black",
                  color: "white",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <EmailIcon />
                Email
              </Button>
            </Grid>
            <Grid xs={3} item></Grid>
            <Grid xs={3} item>
              <Button
                onClick={() => savePaymentOrder()}
                style={{
                  background: "#FF7020",
                  color: "white",
                  borderRadius: "16px",
                }}
              >
                <FileDownloadDoneIcon />
                Done
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h4">รายการอาหาร</Typography>
          <Paper
            component="form"
            elevation={0}
            sx={{
              maxHeight: 50,
              border: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: 7,
            }}
          >
            <IconButton
              style={{ borderRadius: 0, color: "#ccc", cursor: "default" }}
              sx={{ p: "10px" }}
            >
              <GridSearchIcon />
            </IconButton>

            <InputBase
              onChange={(e) => onSearchMenu(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Menu"
            />
          </Paper>
        </Box>

        <Grid xs={12} p={2}>
          <div>
            <Button
              variant="outlined"
              onClick={() =>
                setSelectCategoryMenu({ categoryId: "", categoryName: "" })
              }
              sx={{
                background:
                  selectCategoryMenu?.categoryId == "" ? "orange" : "",
                color:
                  selectCategoryMenu?.categoryId == "" ? "white" : "orange",
              }}
              style={{ borderRadius: 50, margin: 5 }}
            >
              ทั้งหมด
            </Button>
            {allCategorys.map((item: any, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => setSelectCategoryMenu(item)}
                  sx={{
                    background:
                      selectCategoryMenu?.categoryId == item.categoryId
                        ? "orange"
                        : "",
                    color:
                      selectCategoryMenu?.categoryId == item.categoryId
                        ? "white"
                        : "orange",
                  }}
                  style={{ borderRadius: 50, margin: 5 }}
                >
                  {item.categoryName}
                </Button>
              );
            })}
          </div>

          {/* <Button variant='outlined' onClick={() => setSelectCategoryMenu('เมนูแนะนำ')} style={selectCategoryMenu === 'เมนูแนะนำ' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>เมนูแนะนำ</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ส้มตำ')} style={selectCategoryMenu === 'ส้มตำ' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ส้มตำ</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ลาบละน้ำตก')} style={selectCategoryMenu === 'ลาบละน้ำตก' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ลาบละน้ำตก</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ของทอดและย่าง')} style={selectCategoryMenu === 'ของทอดและย่าง' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ของทอดและย่าง</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ผัด')} style={selectCategoryMenu === 'ผัด' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ผัด</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ต้มและแกง')} style={selectCategoryMenu === 'ต้มและแกง' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ต้มและแกง</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ของหวาน & เครื่องดืม')} style={selectCategoryMenu === 'ของหวาน & เครื่องดืม' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ของหวาน & เครื่องดืม</Button>
                    <Button variant='outlined' onClick={() => setSelectCategoryMenu('ไวท์')} style={selectCategoryMenu === 'ไวท์' ? { borderRadius: 50, margin: 5, backgroundColor: '#FFB802', color: 'white' } : { borderRadius: 50, margin: 5 }}>ไวท์</Button> */}
        </Grid>

        <Grid container spacing={2} p={2}>
          {allProductByCategorys.map((item: any, index) => (
            <Grid item key={index} xs={6} sm={6} md={3}>
              <Card sx={{ borderRadius: 3 }} elevation={1}>
                <CardMedia image={item.imageUrl} sx={{ height: 140 }} />
                <Typography sx={{ m: 1 }} variant="h6">
                  {item.product_name}
                </Typography>
                <Typography
                  sx={{ m: 1, color: "#FF7020", fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  {" "}
                  ฿{item.price}
                </Typography>
                <IconButton
                  style={{
                    backgroundColor: "#f8f9fd",
                    width: "40px",
                    height: "40px",
                    borderRadius: 7,
                    left: "10px",
                  }}
                  onClick={() => onAddCart(tableSelected, item.id)}
                >
                  <Add style={{ color: "#8e3dfd" }} />
                </IconButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} p={2} pt={4}>
        <ListOfProductOrders
          allProductOrders={allProductOrders}
          getOrderProducts={getOrderProducts}
        ></ListOfProductOrders>

        <Box
          textAlign={"right"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"end"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            pl={2}
          >
            <Select
              value={tableSelected}
              onChange={(e) => { 
                setTableSelected(e.target.value); 
              }}
              displayEmpty
              size={"small"}
              inputProps={{ "aria-label": "Without label" }}
              style={{ borderRadius: 10, color: "black", height: 30 }}
            >
              <MenuItem value={""}>เลือกโต๊ะ</MenuItem>
              {tables.map((item: any, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    โต๊ะ {item.name}
                  </MenuItem>
                );
              })}
            </Select>

            <div>รวม {allProductOrders.length} รายการ</div>
          </Box>

          <br></br>
          <div>ยอดชำระทั้งหมด</div>
          <div style={{ fontSize: 27 }}>{totalPrice} บาท</div>
          <Box>
            <Button
              onClick={() => setOpen(true)}
              size={"large"}
              style={{
                backgroundColor: "green",
                color: "white",
                borderRadius: 25,
                padding: "10px 35px 10px 35px",
              }}
            >
              ชำระเงิน
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
