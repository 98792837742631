import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Reservation from "./pages/Reservation/Index";
import OrderKitchen from "./pages/Reservation/OrderKitchen";
import FoodMenu from "./pages/FoodMenu/FoodMenu";
import ManagementSell from "./pages/ManagementSell/ManagementSell";
import Dashboard from "./pages/Dashboard/Index";
import ReturnProduct from "./pages/ReturnProduct/ReturnProduct";
import Quotation from "./pages/quotation/Quotation";
import DeliveryNote from "./pages/deliverynote/DeliveryNote";
import Bill from "./pages/bill/Bill";
import Order from "./pages/order";
import AddStock from "./pages/StockManagement/AddStock";
import StockRemainning from "./pages/StockManagement/StockRemaining";
import DeductionStockSetting from "./pages/StockManagement/DeductionStockSetting";
import Revenue from "./pages/Report/Revenue";
import Expense from "./pages/Report/Expense";
import List from "./pages/Report/SaleProducts";
import Sales from "./pages/Report/Sales";
import Summary from "./pages/Report/Summary";
import Employee from "./pages/Manage/Employee";
import Promotion from "./pages/Prom/Promotion";
import Account from "./pages/Expenses/Account";
import Audit from "./pages/Expenses/Audit";
import ScanQrcode from "./pages/ScanQrcode";
import ManageFood from "./pages/ManageFood/Index";
import Login from './pages/Login/Login';
import Type from './pages/Expenses/Type';
import Inventory from "./pages/Report/Inventory";
import ManageCategory from "./pages/ManageCategory/Index";
import ManageTable from "./pages/ManageTable/Index";
import ManageReserves from "./pages/ManageReserves/Index";
import ManageReservesTable from "./pages/ManageReservesTable/Index";

const router = createBrowserRouter([
  {
    path: "/login", element: <Login />
  },
  { path: "/order/:route_id", element: <OrderKitchen /> },
  {
    path: "/qrcode/:token", element: <ScanQrcode />
  },
  { path: "/order", element: <Order /> },
  {
    element: <App />,
    children: [
      { path: "/report", element: <Revenue /> },
      { path: "/", element: <Dashboard /> },
      { path: "/revenue", element: <Revenue /> },
      { path: "/summary", element: <Summary /> },
      { path: "/list", element: <List /> },
      { path: "/expense", element: <Expense /> },
      { path: "/sales", element: <Sales /> },
      { path: "/employee", element: <Employee /> },
      { path: "/prom", element: <Promotion /> },
      { path: "/inventory", element: <Inventory /> },
      { path: "/account", element: <Account /> },
      { path: "/audit", element: <Audit /> },
      { path: "/type", element: <Type /> },
      { path: "/reservation", element: <Reservation /> },
      { path: "/orderkitchen", element: <OrderKitchen /> },
      { path: "/foodmenu", element: <FoodMenu /> },
      { path: "/managemenstell", element: <ManagementSell /> },
      { path: "/returnproduct", element: <ReturnProduct /> },
      { path: "/quotation", element: <Quotation /> },
      { path: "/deliverynote", element: <DeliveryNote /> },
      { path: "/bill", element: <Bill /> },
      { path: "/add-stock", element: <AddStock /> },
      { path: "/manage-food", element: <ManageFood /> },
      { path: "/manage-category", element: <ManageCategory /> },
      { path: "/stock-remaining", element: <StockRemainning /> },
      { path: "/deduction-stock-setting", element: <DeductionStockSetting /> },

      // New
      { path: "/manage-table", element: <ManageTable /> },
      { path: "/manage-reserves", element: <ManageReserves /> },
      { path: "/manage-reserves-table", element: <ManageReservesTable /> },
      
      // { path: "/contact", element: <ContactPage /> },
    ],
  },

]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
