import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Add, Remove } from "@mui/icons-material";
import Order from "../../models/order";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import Back from "../Icon/Back";
import axios from "axios";
import Option from "../../models/option";

interface OrderModalProp {
  open: boolean;
  orderItem: Order;
  onClose: () => void;
  onOrderChange: (count: number) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetailModal(props: OrderModalProp) {
  const { open, orderItem, onClose, onOrderChange } = props;
  const [amount, setAmount] = useState(1);
  const [description, setDescription] = useState(""); // Declare a state variable...
  const [selectedOptions, setSelectedOptions] = useState([]);

  const onAddAmount = () => {
    setAmount((prevAmount) => prevAmount + 1);
  };

  const onRemoveAmount = () => {
    if (amount < 1) return;
    setAmount((prevAmount) => {
      let amount = prevAmount;
      if (amount > 0) {
        amount--;
      }
      return amount;
    });
  };

  const getCurrOrders = () => {
    let currOrders = [];
    let json = localStorage.getItem("orders");
    if (json) {
      currOrders = JSON.parse(json);
    }
    return currOrders;
  };

  const getCurrOrdersCount = () => {
    const currOrders = getCurrOrders();
    const count = currOrders.length;
    return count;
  };

  const onOrdered = () => {
    const currOrders = getCurrOrders();
    const index = currOrders.findIndex(
      (currOrder: Order) => currOrder.id === orderItem.id
    );
    orderItem.amount = amount;
    orderItem.description = description;
    orderItem.option = selectedOptions;
    // orderItem.spicyLevel = spicyLevel;
    if (index === -1) {
      if (orderItem.amount > 0) {
        currOrders.push(orderItem);
      }
    } else {
      if (orderItem.amount === 0) {
        currOrders.splice(index, 1);
      } else {
        currOrders[index] = orderItem;
      }
    }
    const currOrdersCount = getCurrOrdersCount();
    onOrderChange(currOrdersCount);
    localStorage.setItem("orders", JSON.stringify(currOrders));
    onClose();
  };

  React.useEffect(() => {
    const currOrdersCount = getCurrOrdersCount();
    onOrderChange(currOrdersCount);
  }, [onOrderChange]);

  React.useEffect(() => {
    const currOrders = getCurrOrders();
    const index = currOrders.findIndex(
      (item: Order) => item.id === orderItem.id
    );
    if (index > -1) {
      orderItem.amount = currOrders[index].amount;
      orderItem.description = currOrders[index].description;
      orderItem.option = currOrders[index].option;
      setAmount(currOrders[index].amount);
      setDescription(currOrders[index].description);
    }
  }, []);

  const checkedRadio = (event: any) => {
    const target_name = event.target.name;
    const target_value = event.target.value;
    const find_idx = selectedOptions.findIndex((option: any) => {
      return Object.keys(option)[0] == target_name;
    });

    let tmp_selectedOptions: any = selectedOptions;
    if (find_idx == -1) {
      tmp_selectedOptions.push({
        [target_name]: target_value,
      });
    } else {
      tmp_selectedOptions[find_idx][target_name] = target_value;
    }
    
    setSelectedOptions(tmp_selectedOptions);
  };

  const checkedCheckBox = (event: any) => {
    const target_name = event.target.name;
    const target_value = event.target.value;
    const target_checked = event.target.checked;
    const find_idx = selectedOptions.findIndex((option: any) => {
      return Object.keys(option)[0] == target_name;
    });
    let tmp_selectedOptions: any = selectedOptions;

    if (target_checked) {
      if (find_idx == -1) {
        tmp_selectedOptions.push({
          [target_name]: [target_value],
        });
      } else {
        tmp_selectedOptions[find_idx][target_name].push(target_value);
      }
    } else {
      if (find_idx != -1) {
        const find_idx_ofChecked = tmp_selectedOptions[find_idx][
          target_name
        ].findIndex((option: any) => {
          return option == target_value;
        });

        tmp_selectedOptions[find_idx][target_name].splice(
          find_idx_ofChecked,
          1
        );
        if (tmp_selectedOptions[find_idx][target_name].length <= 0) {
          tmp_selectedOptions.splice(find_idx, 1);
        }
      }
    }
    setSelectedOptions(tmp_selectedOptions);
  };

  const [options, setOptions] = useState<Option[]>([]);
  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/options/get/${orderItem.id}`)
      .then((response) => {
        if (response.data.message == "OK") {
          setOptions(response.data.options);
          setSelectedOptions([]);
        }
      });
  }, [orderItem]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Toolbar>
        <IconButton
          edge="start"
          sx={{
            bgcolor: "primary.main",
            width: 30,
            height: 30,
            ":hover": { bgcolor: "primary.main" },
          }}
          onClick={onClose}
        >
          <Back />
        </IconButton>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 400,
          }}
          component="div"
        >
          สั่งอาหาร
        </Typography>
      </Toolbar>
      <img
        alt="somtum"
        src={orderItem.imageUrl}
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5">{orderItem.title}</Typography>
          <form id="options">
            <Divider sx={{ my: 2 }} />
            {options.length > 0 ? (
              <>
                {options.map((option, idx) => {
                  if (option.type == 1) {
                    return (
                      <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              color: "black",
                              "&.Mui-checked": {
                                color: "black",
                              },
                              fontSize: 14,
                            }}
                          >
                            {option.name}
                          </Typography>
                          <Typography
                            sx={{ ml: 1, fontSize: 10 }}
                            color="secondary.light"
                          >
                            ( จำเป็นต้องเลือก 1 อย่าง )
                          </Typography>
                        </Box>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name={option.name}
                            onChange={checkedRadio}
                            // value={`เผ็ดน้อย (3เม็ด)`}
                          >
                            {JSON.parse(option.option).map(
                              (sub_option: any) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      sx={{ color: "secondary.light" }}
                                      value={sub_option.label}
                                      control={<Radio />}
                                      label={sub_option.label}
                                    />
                                  </>
                                );
                              }
                            )}
                          </RadioGroup>
                        </FormControl>
                      </>
                    );
                  } else if (option.type == 2) {
                    return (
                      <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              color: "black",
                              "&.Mui-checked": {
                                color: "black",
                              },
                              fontSize: 14,
                            }}
                          >
                            {option.name}
                          </Typography>
                          <Typography
                            sx={{ ml: 1, fontSize: 10 }}
                            color="secondary.light"
                          >
                            ( เลือกได้หลายอย่างหรือไม่เลือกก็ได้ )
                          </Typography>
                        </Box>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            {JSON.parse(option.option).map(
                              (sub_option: any) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      sx={{ color: "secondary.light" }}
                                      value={sub_option.label}
                                      control={
                                        <Checkbox
                                          name={option.name}
                                          onChange={checkedCheckBox}
                                        />
                                      }
                                      label={sub_option.label}
                                    />
                                  </>
                                );
                              }
                            )}
                          </RadioGroup>
                        </FormControl>
                      </>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </form>

          <Typography sx={{ mt: 2 }} color="secondary">
            รายละเอียดเพิ่มเติม
          </Typography>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{
              borderRadius: 8,
              borderColor: "secondary.light",
              height: 100,
              marginTop: 2,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <IconButton
            onClick={() => onRemoveAmount()}
            sx={{
              bgcolor: "primary.main",
              ":hover": {
                bgcolor: "primary.main",
              },
            }}
          >
            <Remove sx={{ color: "white" }} />
          </IconButton>
          <Typography
            sx={{ minWidth: "50px", textAlign: "center", fontWeight: "700" }}
            color="primary"
          >
            {amount}
          </Typography>
          <IconButton
            onClick={() => onAddAmount()}
            sx={{
              bgcolor: "primary.main",
              ":hover": {
                bgcolor: "primary.main",
              },
            }}
          >
            <Add sx={{ color: "white" }} />
          </IconButton>
          <Button
            onClick={() => onOrdered()}
            sx={{
              color: "white",
              borderRadius: "12px",
              flex: 1,
              ml: 4,
              height: 50,
              justifyContent: "space-between",
            }}
            variant="contained"
          >
            <Typography sx={{ fontSize: 16 }}>ใส่ตระกร้า</Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 400 }}>
              ฿ {orderItem.price * amount}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
