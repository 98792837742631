import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TaskList from "../../Icon/TaskList";
import { IconButton, Stack, TableRow, Typography } from "@mui/material";
import CustomEditModal from "./CustomEditModal";
import { useState } from "react";
import Stock from "../../../models/stock";
import CustomInputText from "../../InputText/CustomInputText";
import { AddBox, Delete } from "@mui/icons-material";

interface EditModalTableProp {
  stock: Stock;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

export default function EditModalTableBody(props: EditModalTableProp) {
  const { stock } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <TableBody>
        <TableRow>
          <StyledTableCell component="th" scope="row" align="center">
            {stock.productCode}
          </StyledTableCell>
          <StyledTableCell align="center">{stock.productName}</StyledTableCell>
          <StyledTableCell
            sx={{ width: "100px" }}
            padding="none"
            align="center"
          >
            <CustomInputText />
          </StyledTableCell>
          <StyledTableCell padding="none" align="center">
            {stock.unit}
          </StyledTableCell>
          <StyledTableCell padding="none" align="center">
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ flexGrow: 1, mx: 2 }}
            >
              <IconButton
                sx={{
                  bgcolor: "#FF9696",
                  borderRadius: "8px",
                  ":hover": { bgcolor: "#FF9696" },
                  mr: 2,
                }}
              >
                <Delete sx={{ color: "white" }} />
              </IconButton>
              <IconButton
                sx={{
                  bgcolor: "#79C447",
                  borderRadius: "8px",
                  ":hover": { bgcolor: "#79C447" },
                  mr: 2,
                }}
              >
                <AddBox sx={{ color: "white" }} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "white",
                    px: 1,
                  }}
                >
                  เพิ่ม
                </Typography>
              </IconButton>
            </Stack>
          </StyledTableCell>
        </TableRow>
      </TableBody>
      {stock && (
        <CustomEditModal
          isOpen={isOpenModal}
          stock={stock}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  );
}
