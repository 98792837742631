import { Axios } from './../shared/Axios';
import qs from 'qs';

class CategoryService extends Axios {

    private static instance: CategoryService;

    static get(): CategoryService {
        if (!CategoryService.instance) {
            CategoryService.instance = new CategoryService();
        }
        return CategoryService.instance;
    }

    async getCategorys(): Promise<any> {
        return this.instance.get(`/categories`)
    }

    async saveCategory(data: object): Promise<any> {
        return this.instance.post(`/categories`, data)
    }

    async deleteCategory(categoryId: string): Promise<any> {
        return this.instance.delete(`/categories/${categoryId}`)
    }

    async updateCategory(categoryId: string, data: object): Promise<any> {
        return this.instance.patch(`/categories/${categoryId}`, data)
    }





}


const categoryService = CategoryService.get();

export { categoryService as CategoryService }