import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StockTableBody from "./StockTableBody";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E7E8F2",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".MuiTableCell-root": {
    borderLeft: "1px solid #D9D9D9",
  },
}));

export default function StockTable() {
  return (
    <TableContainer component={Paper}>
      <Table
        className="tableBorder"
        sx={{ minWidth: 700 }}
        aria-label="customized table"
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>รหัสสินค้า</StyledTableCell>
            <StyledTableCell align="center">รายการสินค้า</StyledTableCell>
            <StyledTableCell align="center">ตัดสต๊อก</StyledTableCell>
            <StyledTableCell align="center">ต้นทุน</StyledTableCell>
            <StyledTableCell align="center">ราคาขาย</StyledTableCell>
            <StyledTableCell align="center">กำไร</StyledTableCell>
            <StyledTableCell align="center">ขายได้</StyledTableCell>
            <StyledTableCell align="center">หน่วย</StyledTableCell>
            <StyledTableCell align="center">จัดการ</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <StockTableBody />
      </Table>
    </TableContainer>
  );
}
