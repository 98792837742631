import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  AssignmentOutlined,
  BluetoothSearching,
  DeleteOutline,
  EditOutlined,
  ImageSearch,
  PrivacyTipOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import CreateOrUpdate from "./CreateOrUpdate";
import _ from "lodash";
import Swal from "sweetalert2";
import { ManageTableService } from "../../services/ManageTableService";
import { ReservesService } from "../../services/ReservesService";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 19,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 19,
    position: "relative",
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function ManageReservesTable() {
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [isDailog, setIsDialog] = React.useState<boolean>(false);
  const [editRecord, setEditRecord] = React.useState<any>();
  const [fullWidth, setFullWidth] = React.useState<boolean>(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const [data, setData] = React.useState<Array<any>>([]);

  const [search, setSearch] = React.useState<string>("");

  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const today = dayjs().startOf("day"); // วันนี้ในรูปแบบเริ่มต้นวัน
  // เช็คว่ามีการเลือกวันที่ที่ไม่ใช่วันนี้
  const [isNotToday, setIsNotToday] = React.useState<boolean>(false);

  const [action, setAction] = React.useState<number>(0);

  const [idResv, setIdResv] = React.useState("");
  const [isStatus, setIsStatus] = React.useState<boolean>(false);
  const [nameTable, setNameTable] = React.useState("");

  const columns: GridColDef[] = [
    {
      field: "name_customer",
      headerName: "ลูกค้า",
      headerAlign: "center",
      align: "left",
      flex: 1,
      width: 300,
    },
    {
      field: "name_table",
      headerName: "ชื่อโต๊ะ",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "resv_date",
      headerName: "วันที่จอง",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        const dateValue = row.row.resv_date;
        // ใช้ dayjs เพื่อแปลงและจัดรูปแบบวันที่
        const formattedDate = dayjs(dateValue).format("DD/MM/YYYY"); // ตัวอย่างรูปแบบวันที่

        return formattedDate;
      },
    },
    {
      field: "resv_time_start",
      headerName: "เวลาเข้า",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "resv_time_end",
      headerName: "เวลาออก",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "customer_count_res",
      headerName: "จำนวนลูกค้า",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status_date",
      headerName: "ล่วงหน้า",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return row.row.status_date === 1 ? "/" : "";
      },
    },
    {
      field: "amount",
      headerName: "จำนวนเงิน",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return row.row.status_date === 1 ? row.row.amount : "";
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return row.row.status === "usering"
          ? "ใช้งาน"
          : row.row.status === "cancel"
          ? "ยกเลิก"
          : "จอง";
      },
    },
    {
      field: "idResv",
      headerName: " ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return (
          <>
            <Button
              onClick={async () => {
                const rowData = row.row;
                setValue("status", rowData.status);
                setValue("nameCustomer", rowData.name_customer);
                setValue("customerCount", rowData.customer_count_res);
                setValue("email", rowData.email);
                setValue("telephone", rowData.telephone);
                setValue("detail", rowData.detail);

                const dateValue = rowData.resv_date;
                setSelectedDate(dayjs(dateValue));
                setValue("resv_date", dateValue);

                setValue("amount", rowData.amount);
                setIdResv(rowData.id);
                setAction(1);
                setNameTable(rowData.name_table);
                setValue(
                  "resv_time_start",
                  dayjs(rowData.resv_time_start, "HH:mm")
                );
                setValue(
                  "resv_time_end",
                  dayjs(rowData.resv_time_end, "HH:mm")
                );

                setIsNotToday(rowData.status_date === 0 ? false : true);
                setValue("status_date", rowData.status_date);

               if (rowData.status === "reserve") { 
                  setIsStatus(true);
                }else {
                  setIsStatus(false);
                }

                setIsDialog(true);
                setEditRecord(row.row);

             
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={async () => deleteData(`${row.id}`)}
              disabled={!(row.row.status === "reserve")}
            >
              <DeleteOutline />
            </Button>
          </>
        );
      },
    },
  ];

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = {
      search: search,
    };
    const res = await ReservesService.getDataSearch(data);
    if (res?.status == 200) {
      const { data } = res.response;
      setData(data);
    } 
  };

 
  const deleteData = async (dataId: string) => {
    Swal.fire({
      title: "Are you sure to delete ?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonColor: "orange",
      confirmButtonText: "Confirm. Delete it!",
      denyButtonText: `Cancel`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await ReservesService.deleteData(dataId);
        if (res?.status == 200) {
          const indexData = _.findIndex(data, { id: +dataId });
          const _foods = [...data];
          _foods.splice(indexData, 1);
          setData(_foods);
        }
      } else if (result.isDenied) {
      }
    });
  };

  const onUpdateStatus = async () => {
    try {
      // เรียกใช้งานบริการเพื่อบันทึกข้อมูล
      const res = await ReservesService.updateStatus(idResv, watch("status"));

      if (res?.status == 200) {
        // const { data } = res.response;
        //props.onResponse(data, false);
        Swal.fire({
          icon: "success",
          text: res?.message,
        });
        //props.onClose();
        getData();
        setIsDialog(false);
      } else {
        Swal.fire({
          icon: "error",
          text: res?.message,
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        text: "An error occurred while saving the data.", // ข้อความแสดงข้อผิดพลาดในกรณีที่เกิดข้อผิดพลาด
      });
    }
  };

  return (
    <Grid container p={4} sx={{ bgcolor: "white" }}>
      <Dialog
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setIsDialog(false);
          }
        }}
        open={isDailog}
        fullWidth
        PaperProps={{ sx: { borderRadius: "13px" } }}
      >
        <DialogTitle id="form-dialog-title">จองโต๊ะ : {nameTable}</DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 3 }}>
          {action !== 0 && (
            <Grid container sx={{ pt: 2 }}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  สถานะ
                </InputLabel>
                <Select
                  disabled={!isStatus}
                  displayEmpty
                  defaultValue={watch("status")}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "45px", width: "100px" }}
                  {...register("status", { required: true })}
                >
                  <MenuItem value="reserve">จอง</MenuItem>
                  <MenuItem value="usering">ใช้งาน</MenuItem>
                  <MenuItem value="cancel">ยกเลิก</MenuItem>
                </Select>
              </FormControl>
              {isStatus && (
                <IconButton
                  style={{
                    backgroundColor: "#f8f9fd",
                    width: "40px",
                    height: "40px",
                    borderRadius: 7,
                    left: "10px",
                  }}
                  onClick={() => onUpdateStatus()}
                >
                  <AssignmentOutlined style={{ color: "#8e3dfd" }} />
                </IconButton>
              )}
            </Grid>
          )}

          <Grid container sx={{ pt: 2 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                ชื่อ - นามสกุล
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                {...register("nameCustomer", { required: true })}
              />
            </FormControl>
            &nbsp;&nbsp;
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                จำนวนคน
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                {...register("customerCount", { required: true })}
              />
            </FormControl>
          </Grid>
          <Grid container sx={{ pt: 2 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                อีเมล
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                {...register("email", {
                  required: "จำเป็นต้องกรอกอีเมล",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "รูปแบบอีเมลไม่ถูกต้อง",
                  },
                })}
              />
              {/* ตรวจสอบและแสดงข้อความ error เฉพาะเมื่อมี */}
              {errors.email && typeof errors.email.message === "string" && (
                <p>{errors.email.message}</p>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                เบอร์โทรศัพท์
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                {...register("telephone", { required: true })}
              />
            </FormControl>
          </Grid>
          <br />
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="bootstrap-input">
              รายละเอียด
            </InputLabel>
            <BootstrapTextareaAutosize
              placeholder="รายละเอียด"
              style={{ borderRadius: 19, padding: 10 }}
              minRows={3}
              {...register("detail", { required: true })}
            />
          </FormControl>
          <br />
          <FormControl variant="standard" sx={{ p: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันเวลาจอง"
                value={selectedDate}
                onChange={(newValue: any) => {
                  setSelectedDate(newValue);

                  setValue("resv_date", newValue);
                  const checkToDay = !newValue.isSame(today, "day");
                  setIsNotToday(checkToDay);
                  setValue("status_date", checkToDay);

                  setValue("amount", "");
                  // setValue("resv_date_start", newValue);

                  // setValue("resv_time_start", newValue);
                }}
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl variant="standard" sx={{ p: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                // disablePast
                label="เวลาเข้า"
                value={watch("resv_time_start")}
                onChange={(newValue: any) => {
                  setValue("resv_time_start", newValue);
                }}
                ampm={false} // This ensures 24-hour format
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl variant="standard" sx={{ p: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                // disablePast
                label="ถึง"
                value={watch("resv_time_end")}
                onChange={(newValue: any) => {
                  setValue("resv_time_end", newValue);
                }}
                ampm={false} // This ensures 24-hour format
                // minTime={minTime}
              />
            </LocalizationProvider>
          </FormControl>
          <br />
          {isNotToday && (
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                จำนวนเงิน
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                {...register("amount", { required: isNotToday })}
              />
            </FormControl>
          )}
          <DialogActions sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} justifyContent="flex-end">
                <Button
                  fullWidth
                  size={"large"}
                  style={{
                    border: "1px solid #ff6801",
                    color: "#ff6801",
                    borderRadius: 25,
                    padding: "10px 35px 10px 35px",
                  }}
                  onClick={() => setIsDialog(false)}
                >
                  ปิด
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          ประวัติการจองโต๊ะ
        </Typography>
      </Grid>

      <Box>
        <Typography>ค้นหา</Typography>
        <TextField
          sx={{
            width: { xs: "100%", md: "291px" },
            height: "42px",
            borderRadius: "8px",
            mb: { xs: 2, md: 0 },
          }}
          value={search}
          variant="outlined"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />

        <IconButton
          style={{
            backgroundColor: "#f8f9fd",
            width: "40px",
            height: "40px",
            borderRadius: 7,
            left: "10px",
          }}
          onClick={() => getData()}
        >
          <ImageSearch style={{ color: "#8e3dfd" }} />
        </IconButton>

        {/* <Button
          onClick={() => addData()}
          variant="contained"
          style={{ background: "#13ad13", color: "white" }}
        >
           
        </Button> */}
      </Box>
      {/* <Box pt={2} pb={2} display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => addData()}
          variant="contained"
          style={{ background: "#13ad13", color: "white" }}
        >
          + เพิ่มโต๊ะ
        </Button>
      </Box> */}

      <Box
        sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
        style={{ overflow: "auto" }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100, 500]}
          sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
          getRowId={(row: GridRowModel) => row.id}
          getRowHeight={() => "auto"}
          disableEval
          showCellVerticalBorder={true}
          disableColumnMenu
          disableColumnFilter
          rows={data}
          columns={columns}
        />
      </Box>
    </Grid>
  );
}
