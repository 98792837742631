import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/AddStock/StockTable";
import React from "react";
import { StockService } from "../../../services/StockService";
import { Download, PlayArrow, Upload } from "@mui/icons-material";
import Swal from 'sweetalert2';

export default function AddStock() {

  const stockRef = React.useRef<any>(null)
  const [stocks, setStocks] = React.useState<any[]>([])

  React.useEffect(() => {
    getStocks();
  }, [])

  const getStocks = async () => {
    const res = await StockService.getStocks();
    if (res?.status == 200) {
      const { stocks } = res.response;
      setStocks(stocks)
    }
  }

  const saveStock = async () => {
    const results = stockRef.current?.getResults()
    const res = await StockService.saveStock({
      datas: results
    })
    if (res?.status == 200) {
      Swal.fire({
        icon: "success",
        text: res?.message
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message
      });
    }

  }

  const downloadStock = async() => {
    try {
      const response = await StockService.downloadStock();

      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.xlsx'); // Set the filename
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }

  }

  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          รับสินค้าเข้าสต๊อก (เพิ่ม-ลด)
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <Stack gap={2} direction="row" sx={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => downloadStock()}
            variant="contained"
            color="warning"
            sx={{ color: "white" }}
            startIcon={<Download />}
            endIcon={<PlayArrow sx={{ transform: "rotate(90deg)" }} />}
          >
            ดาวโหลดข้อมูลสินค้า
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: (theme) => theme.palette.grey[300], color: "black" }}
            startIcon={<Upload />}
          >
            อัพโหลดข้อมูล
          </Button>
          <Button
            onClick={() => saveStock()}
            variant="contained" color="success" sx={{ color: "white" }}>
            ยืนยัน อัพเดทสต๊อก
          </Button>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First row */}
          <Grid item xs={6}>
            <CustomInputText label={"วันที่"} />
          </Grid>
          <Grid item xs={6}>
            <CustomInputText label={"บริษัทคู่ค้า"} />
          </Grid>

          {/* Second row */}
          <Grid item xs={6}>
            <Typography>ผู้จัดทำรายการ : admin</Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomInputText label={"เลขที่เอกสารอ้างอิง "} />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <StockTable
            ref={stockRef}
            stocks={stocks}
          />

          <Typography sx={{ fontSize: "15px", fontWeight: 500, mt: 2 }}>
            หมายเหตุ หากต้องการลดจำนวนสินค้าในคลัง ให้ใส่เครื่องหมาย (ลบ) -
            หน้าตัวแลขที่ต้องการเช่น -2
          </Typography>
        </Box>
      </Paper>
    </>
  );
}
