import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Item from "./Item";
import NavIcon1 from "../assets/images/navbar/nav-1.png";
import NavIcon2 from "../assets/images/navbar/nav-2.png";
import NavIcon3 from "../assets/images/navbar/nav-3.png";
import NavIcon4 from "../assets/images/navbar/nav-4.png";
import NavIcon5 from "../assets/images/navbar/nav-5.png";
import NavIcon6 from "../assets/images/navbar/nav-6.png";
import NavIcon7 from "../assets/images/navbar/nav-7.png";
import NavIcon8 from "../assets/images/navbar/nav-8.png";
import NavIcon9 from "../assets/images/navbar/nav-9.png";
import { FoodBankOutlined } from "@mui/icons-material";

interface MenuProps {
  title: string;
  to?: string;
  open?: boolean;
  children?: MenuProps[];
  iconImgFullPath?: string;
}

export const MainListItems = () => {
  const [menus, setMenus] = React.useState<MenuProps[]>([
    {
      title: "รายงานภาพรวม",
      to: "/",
      iconImgFullPath: NavIcon1,
    },
    {
      title: "รายการอาหาร",
      to: "/foodmenu",
      iconImgFullPath: NavIcon2,
    },
    {
      title: "จัดการการอาหาร",
      iconImgFullPath: NavIcon8,
      children: [
        {
          title: "เพิ่มหมวดหมู่",
          to: "/manage-category",
        },
        {
          title: "เพิ่มรายการอาหาร",
          to: "/manage-food",
        },
      ],
    },
    {
      title: "จัดการการขาย",
      iconImgFullPath: NavIcon3,
      children: [
        {
          title: "รายการขายทั้งหมด",
          to: "/managemenstell",
        },
        {
          title: "คืนรายการสินค้า",
          to: "/returnproduct",
        },
      ],
    },
    {
      title: "คลังเอกสาร",
      iconImgFullPath: NavIcon4,
      children: [
        {
          title: "ใบเสอนราคา",
          to: "/quotation",
        },
        {
          title: "ใบส่งสินค้า",
          to: "/deliverynote",
        },
        {
          title: "ใบเสร็จรับเงิน",
          to: "/bill",
        },
      ],
    },
    {
      title: "จัดการสต๊อก",
      iconImgFullPath: NavIcon5,
      children: [
        {
          title: "รับสินค้าเข้าสต๊อก",
          to: "/add-stock",
        },
        {
          title: "สต๊อกคงเหลือ",
          to: "/stock-remaining",
        },
        {
          title: "ตั้งค่าตัดสต๊อก",
          to: "/deduction-stock-setting",
        },
      ],
    },
    {
      title: "จัดการบัญชีรายจ่าย",
      iconImgFullPath: NavIcon6,
      children: [
        {
          title: "จัดทำข้อมูลรายจ่าย",
          to: "/account",
        },
        {
          title: "รายการรายจ่าย",
          to: "/audit",
        }
      ],
    },
    {
      title: "จัดการพนักงาน",
      iconImgFullPath: NavIcon7,
      children: [
        {
          title: "พนักงาน",
          to: "/employee",
        },
      ],
    },
    {
      title: "รายงาน",
      iconImgFullPath: NavIcon8,
      children: [
        {
          title: "ยอดขาย",
          to: "/sales",
        },
        {
          title: "รายการขายสินค้า",
          to: "/list",
        },
        {
          title: "สรุปการชำระเงิน",
          to: "/summary",
        },
        {
          title: "รายงานรายรับ",
          to: "/revenue",
        },
        {
          title: "รายงานรายจ่าย",
          to: "/expense",
        },
        {
          title: "รายงานสินค้าคงเหลือ",
          to: "/inventory",
        },
      ],
    },
    {
      title: "ส่งเสริมการขาย",
      iconImgFullPath: NavIcon9,
      to: "/prom",
    },
    {
      title: "โต๊ะอาหารทั้งหมด",
      to: "/reservation",
    },
    // {
    //   title: "สั่งอาหาร",
    //   to: "/order",
    // },
  ]);

  return (
    <React.Fragment>
      {menus.map((item, index) => {
        return <Item {...item} key={index}></Item>;
      })}
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
  </React.Fragment>
);
