import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stock from "../../../models/stock";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditModalTableBody from "./EditModalTable";
import StockSelect from "./StockSelect";

interface CustomEditModalProp {
  isOpen: boolean;
  stock: Stock;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {},
}));

export default function CustomEditModal(props: CustomEditModalProp) {
  const { isOpen, stock, onClose } = props;
  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClose} open={isOpen} fullWidth maxWidth="md">
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "#FFB802" }}
          id="customized-dialog-title"
        >
          <Typography
            sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
          >
            ตั้งค่าตัดสต๊อก
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: "100%", flexGrow: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            sx={{ width: "100%" }}
          >
            <Typography sx={{ mr: 2, fontSize: "18px", fontWeight: 500 }}>
              รหัส/เลขบาร์โค้ด {stock.productCode} ชื่อสินค้า:
              {stock.productName}
            </Typography>
            <StockSelect defaultSelected="เลือกรายการ" />
          </Stack>
          <Box sx={{ pt: 2 }}>
            <Divider />
          </Box>
          <TableContainer>
            <Table
              className="tableBorder"
              sx={{ minWidth: 700 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      รหัสสินค้า
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      รายการ
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      จำนวน
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      หน่วย
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      จัดการ
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <EditModalTableBody stock={stock} />
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
