import { Button, Stack } from "@mui/material";
import { Download, PlayArrow, Print, Upload } from "@mui/icons-material";

export default function ButtonList() {
  return (
    <Stack gap={2} direction="row" sx={{ justifyContent: "flex-end" }}>
      <Button variant="contained" sx={{ color: "white", bgcolor: "#FF6F6F" }}>
        รีเซ็ทสต๊อกคงเหลือ
      </Button>
      <Button
        variant="contained"
        sx={{ bgcolor: "#38DF67", color: "white" }}
        startIcon={<Download />}
      >
        ดาวโหลดข้อมูลไฟล์ Excel
      </Button>
      <Button
        variant="contained"
        sx={{ bgcolor: "#38DF67", color: "white" }}
        startIcon={<Download />}
      >
        ดาวโหลดใบสั่งสินค้า
      </Button>

      <Button
        variant="contained"
        sx={{ color: "white", bgcolor: "#4DBCFA" }}
        startIcon={<Print />}
      >
        พิมพ์
      </Button>
      <Button
        variant="contained"
        color="warning"
        sx={{ color: "white" }}
        startIcon={<Download />}
        endIcon={<PlayArrow sx={{ transform: "rotate(90deg)" }} />}
      >
        ดาวโหลดข้อมูลสินค้า
      </Button>
    </Stack>
  );
}
