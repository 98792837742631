import { Box, Button, FormControl, Grid, IconButton, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import * as React from 'react';
import { DataGrid, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { DeleteOutline, PrivacyTipOutlined, TaskOutlined } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import TaskListIcon from '../../assets/images/task-list.png';
import TrashIcon from '../../assets/images/trash.png';
import { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
const socket = io(`${process.env.REACT_APP_API_URL}`, {
    withCredentials: true,
});
type Order = {
    id: number;
    menu_name: string;
    table_name: string;
    status: number;

};



export default function OrderKitchen() {
    const params = useParams();
    const [titleRoute, setTitleRoute] = useState("");
    const [orders, setOrders] = useState<Order[]>([]);

    const handleonchange = (event: SelectChangeEvent, id: number) => {

        const updatedOrders = orders.map((old) => {
            if (old.id === id) {
                axios.put(`${process.env.REACT_APP_API_URL}/orders/order/${id}`, { status: event.target.value })
                    .then(response => {
                        console.log('PUT request successful');
                        console.log('Response:', response.data);
                    })
                    .catch(error => {
                        console.error('Error while making PUT request:', error);
                    });
                return { ...old, status: parseInt(event.target.value, 10) };
            } else {
                return old;
            }
        });
        setOrders(updatedOrders);
    };


    const columns: GridColDef[] = [
        {
            field: 'menu_name', headerName: 'รายการอาหาร', headerAlign: 'center', align: 'center', flex: 1, renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    <div>{params.row.menu_name} </div>
                    <div>{JSON.parse(params.row.option).map((option: any) => {
                        return (
                            <ListItemText secondary={`- (${Object.keys(option)[0]}) ${option[Object.keys(option)[0]]}`} />
                        )
                    })}</div>
                </div>
            ),
        },
        { field: 'table_name', headerName: 'โต๊ะ', headerAlign: 'center', align: 'center', flex: 1 },
        {
            field: 'lastName', headerName: 'สถานะ', headerAlign: 'center', align: 'center', flex: 1, renderCell: (param) => (
                <Box>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>{
                            param.row.status === 0 ? (
                                <div style={{
                                    background: '#f0b433',
                                    width: 17,
                                    height: 17,
                                    borderRadius: 50,
                                    border: ' 3px solid rgb(253 219 147)'
                                }}></div>
                            ) : (
                                <div style={{
                                    background: '#14c91c',
                                    width: 17,
                                    height: 17,
                                    borderRadius: 50,
                                    border: ' 3px solid rgb(207 238 208)'
                                }}></div>
                            )
                        }
                        &nbsp;&nbsp;
                        <Select
                            value={param.row.status}
                            onChange={(event) => handleonchange(event, param.row.id)}
                            displayEmpty
                            size={'small'}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ borderRadius: 10, color: 'black', height: 30 }}
                        >
                            <MenuItem value={0}>รับออเดอร์</MenuItem>
                            <MenuItem value={1}>ยืนยันออเดอร์</MenuItem>
                            <MenuItem value={2}>สำเร็จ</MenuItem>
                        </Select>
                    </div>
                </Box>
            )
        },

    ];


    socket.on('routes', (res: any) => {
        // if (res === 1) {
        //     window.location.reload();
        // }
        axios.get(`${process.env.REACT_APP_API_URL}/orders/get/route/${params.route_id}`).then((response) => {
            if (response.data.message == 'OK') {
                setOrders(response.data.order_list);
            }
        });

    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/routes/get/${params.route_id}`).then((response: any) => {
            if (response.data.message == 'OK') {
                setTitleRoute(response.data.route.name)

            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/orders/get/route/${params.route_id}`).then((response) => {
            if (response.data.message == 'OK') {
                setOrders(response.data.order_list);
            }
        });

    }, [])

    return (
        <Grid container p={4} sx={{ bgcolor: 'white' }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                <div>
                    <Typography variant="h5" component="h2">
                        {titleRoute}
                    </Typography>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#f0b433',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(253 219 147)'
                }}></div>&nbsp;&nbsp;รับออเดอร์</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#14c91c',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(207 238 208)'
                }}></div>&nbsp;&nbsp;ยืนยันออเดอร์</div>

            </div>


            <br></br><br></br><br></br>

            <Box sx={{ width: { xs: 700, sm: 700, md: '100%' } }} style={{ overflow: 'auto' }} >
                <DataGrid
                    sx={{ width: { xs: 700, sm: 700, md: '100%' } }}
                    // getRowId={(row: GridRowModel) => row.id}
                    getRowHeight={() => 'auto'}
                    disableEval
                    // showCellVerticalBorder={true}
                    disableColumnMenu
                    disableColumnFilter
                    rows={orders.filter((prev) => prev.status !== 2)}
                    columns={columns}
                    hideFooter
                />
            </Box>
        </Grid>
    )
}