import { Axios } from '../shared/Axios';
import qs from 'qs';

class ReportService extends Axios {

    private static instance: ReportService;

    static get(): ReportService {
        if (!ReportService.instance) {
            ReportService.instance = new ReportService();
        }
        return ReportService.instance;
    }

    async getReportBill(data: Object): Promise<any> {
        return this.instance.post(`/reports/bill`, data)
    } 
     
}


const reportService = ReportService.get();

export { reportService as ReportService }