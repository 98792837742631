import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MdUnfoldMore } from "react-icons/md";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const EditCustomerSeats = (props:any) => {
    const cookies = new Cookies(null, { path: '/' });
    const [customerSeats, setCustomerSeats] = useState<any>("0");
    const [notify, setNotify] = useState({
        text: '',
        color: 'black'
    });

    useEffect(() => {
        setCustomerSeats(props.selectedTable.customerSeats)
    }, [props])

    const handleChangeCustomSeats = (e:any) => {
        resetNotify();
        setCustomerSeats(e.target.value);
    }

    const resetNotify = () => {
        setNotify({
            text: '',
            color: 'black'
        });
    }

    const EditCustomerSeatsButton = () => {
        resetNotify();
        if(isNaN(parseInt(customerSeats)))
        {
            setNotify({
                text: 'กรุณากรอกเฉพาะตัวเลข',
                color: 'red'
            });
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/tables/editCustomerSeats`, {
            table_id: props.selectedTable.id,
            customerSeats: customerSeats
        }, {
            headers: {
                authorization: cookies.get('employee_token')
            }
        }).then((response:any) => {
            if(response.data.message == 'OK')
            {
                props.reloadTables();
            }
        })
    }

    return (
        <>
            <Typography style={
                {
                    marginTop: "1rem"
                }
            }>จำนวนที่นั่งของลูกค้า {
                notify.text != '' && <small style={
                    {
                        color: notify.color || 'black'
                    }
                }>({notify.text})</small>
            }</Typography>
            <TextField
                sx={{
                width: {xs: '100%', md: '100%'},
                height: '42px',
                borderRadius: '8px',
                mb: { xs: 2, md: 2 },
                }}
                variant="outlined"
                fullWidth
                value={customerSeats}
                onChange={handleChangeCustomSeats}
            />
            <Grid container justifyContent='center'>
                <Button variant="contained" style={
                    {
                        color: "white"
                    }
                } onClick={EditCustomerSeatsButton}>แก้ไขจำนวนที่นั่งของลูกค้า</Button>
            </Grid>
            <hr/>
        </>
    )
}

export default EditCustomerSeats;