import { Grid } from "@mui/material";
import Order from "../../models/order";
import OrderItemCard from "./OrderItemCard";
import { isEmpty } from "lodash";

interface OrderItemProp {
  orderItems: Order[];
  onOrderChange: (count: number) => void;
}

export default function OrderList(props: OrderItemProp) {
  const { orderItems, onOrderChange } = props;

  return (
    <>
      {!isEmpty(orderItems) ? (
        <Grid container spacing={{ xs: 2, sm: 4, md: 6 }} sx={{ p: 4 }}>
          {orderItems.map((item) => (
            <Grid item xs={6}>
              <OrderItemCard
                orderItem={item}
                key={item.id}
                onOrderChange={onOrderChange}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>ไม่มีรายการอาหาร</>
      )}
    </>
  );
}
