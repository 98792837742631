import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { LocalStorageService } from "../services/LocalStorageService";

let isRefreshing = false;
let failedQueue: Array<any> = [];

export abstract class Axios {

    protected instance: AxiosInstance;
    protected token: any;
    protected readonly baseURL: string;

    public constructor() {
        this.baseURL = `${process.env.REACT_APP_API_URL}`
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: 10000,
            responseType: "json",
            headers: {
                'Accept-Version': 1,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                "Origin": `${process.env.REACT_APP_API_URL}`
            },
            data: {},
        });

        this.initializeRequestInterceptor();
        this.initializeResponseInterceptor();
        // Instantiate the interceptor

    }

    private initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this.handleRequest);
    };


    private initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(response => {
            return response?.data;
        }, this.handleError);
    }

    private handleRequest = async (config: AxiosRequestConfig | any) => {
        config.headers['Authorization'] = `Bearer ${this.token}`;
        const jwtToken = LocalStorageService.getAccessToken()
        if (jwtToken != null) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }
        return config;
    };


    private processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        })
        failedQueue = [];
    }

    private handleError = async (error: AxiosError) => {

        const errorData = error.response?.data as any;
        const originalRequest = error?.config as any;
        const statusCode = error?.response?.status
        const errorMessage = errorData?.errors?.join('')


        if (statusCode === 401 && errorMessage == 'jwt expired' && !originalRequest?._retry) {


            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }


            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(async (resolve, reject) => {
                resolve(axios(originalRequest));
            })

        }

        return error?.response
    }

}

