import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  DeleteOutline,
  EditOutlined,
  PrivacyTipOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import CreateOrUpdate from "./CreateOrUpdate";
import _ from "lodash";
import Swal from "sweetalert2"; 
import { ManageTableService } from "../../services/ManageTableService";

const defaultImage =
  "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg";

export default function ManageTable() {
  const [isDailog, setIsDialog] = React.useState<boolean>(false);
  const [editRecord, setEditRecord] = React.useState<any>();
  const [fullWidth, setFullWidth] = React.useState<boolean>(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const [data, setData] = React.useState<Array<any>>([]);

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "รหัส",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "ชื่อโต๊ะ",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
        field: "seats",
        headerName: "จำนวนที่นั่ง",
        headerAlign: "center",
        align: "center",
        flex: 1,
    },
    {
        field: "isActive",
        headerName: "สถานะ",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (row) => {
            return (
               
                  row.row.isActive === 1 ? "ใช้งาน" : "ยกเลิก" 
               
            );
          }, 
    },
    {
      field: "id1",
      headerName: " ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (row) => {
        return (
          <>
            <Button
              onClick={async () => {
                setIsDialog(true);
                setEditRecord(row.row);
              }}
            >
              <EditOutlined />
            </Button>
            <Button onClick={async () => deleteData(`${row.id}`)}>
              <DeleteOutline />
            </Button>
          </>
        );
      },
    },
  ];

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await ManageTableService.getData();
    if (res?.status == 200) {
      const { data } = res.response;
      console.log(JSON.stringify(data))
      setData(data);
    }
  };

  const addData =  () => {
    setEditRecord([]);
    setIsDialog(true);
  };

  const deleteData = async (dataId: string) => {
    Swal.fire({
      title: "Are you sure to delete ?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonColor: "orange",
      confirmButtonText: "Confirm. Delete it!",
      denyButtonText: `Cancel`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await ManageTableService.deleteData(dataId);
        if (res?.status == 200) {
          const indexData = _.findIndex(data, { id: +dataId });
          const _foods = [...data];
          _foods.splice(indexData, 1);
          setData(_foods);
        }
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Grid container p={4} sx={{ bgcolor: "white" }}>
      <Dialog
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setIsDialog(false);
          }
        }}
        open={isDailog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ sx: { borderRadius: "13px" } }}
      >
        <DialogTitle id="form-dialog-title">รายละเอียด</DialogTitle>
        <Divider></Divider>
        <DialogContent sx={{ p: 3 }}>
          <CreateOrUpdate
            editRecord={editRecord}
            onClose={() => setIsDialog(false)}
            onResponse={(table: any, isUpdate: boolean) => {
              if (isUpdate) {
                const _data = table[0];
                console.log("_data"+JSON.stringify(_data))
                const foundIndex = _.findIndex(data, {
                  id: +_data.id,
                });
                if (foundIndex >= 0) {
                  const b = [...data];
                  b[foundIndex] = _data;
                  setData(b);
                }
              } else {
                //create
                setData((p) => [...table, ...p]);
              }
            }}
          ></CreateOrUpdate>
        </DialogContent>
      </Dialog>

      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          จัดการโต๊ะ
        </Typography>
      </Grid>

      <Box pt={2} pb={2} display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => addData()}
          variant="contained"
          style={{ background: "#13ad13", color: "white" }}
        >
          + เพิ่มโต๊ะ
        </Button>
      </Box>

      <Box
        sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
        style={{ overflow: "auto" }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100, 500]}
          sx={{ width: { xs: 700, sm: 700, md: "100%" } }}
          getRowId={(row: GridRowModel) => row.id}
          getRowHeight={() => "auto"}
          disableEval
          showCellVerticalBorder={true}
          disableColumnMenu
          disableColumnFilter
          rows={data}
          columns={columns}
        />
      </Box>
    </Grid>
  );
}
