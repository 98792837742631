import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { MdOutlineFileDownload } from "react-icons/md";
import axios from "axios";
import SummariesModel from "../../models/report";
import dayjs from "dayjs";
import { ConvertGMTToYYYYMMDDFormat } from "../../utils/date";

export default function Summary() {
  const [selectedDate, setSelectedDate] = useState<string>(
    `${ConvertGMTToYYYYMMDDFormat(`${dayjs()}`)}`
  );
  const [listSummaries, setListSummaries] = useState<SummariesModel[]>();

  const getListSummaries = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/summaries`)
      .then((response) => {
        setListSummaries(response.data.response);
      });
  };

  const onSelectDate = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/summaries?date=${selectedDate}`
      )
      .then((response) => {
        setListSummaries(response.data.response);
      });
  };

  useEffect(() => {
    getListSummaries();
  }, []);

  return (
    <Grid container sx={{ flexGrow: 1, width: "100%" }}>
      <Grid
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={12}
        sx={{
          bgcolor: "#FFB802",
          width: "100%",
          mb: 2,
          mt: 0,
          ml: { xs: 0, md: 0 },
          p: 3,
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายงานยอดประเภทชำระเงิน
        </Typography>
      </Grid>

      <Paper
        sx={{
          width: { xs: "95%", md: "98%" },
          p: 3,
          ml: { xs: 1, md: 2 },
          mt: -1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>ปี</Typography>
            <input
              type="date"
              onChange={(event) => setSelectedDate(event.target.value)}
              style={{
                height: "43px",
                borderRadius: "12px",
                width: "143px",
                marginRight: "12px",
              }}
              defaultValue={selectedDate}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            direction="row"
            justifyContent="flex-start"
            sx={{ mt: 4, ml: { xs: 0, md: -60 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#FF9B28",
                color: "#FFFFFF",
                padding: "9px 10px 9px 10px",
              }}
              sx={{
                width: { xs: "100%", md: "151px" },
                height: "43px",
                fontSize: "15px",
              }}
              onClick={onSelectDate}
            >
              ค้นหา
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, md: -12 }, ml: { xs: 0, md: 100 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#38DF67",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "219px" },
                height: "43px",
                fontSize: "15px",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              <IconButton color="inherit" size="medium">
                <MdOutlineFileDownload />
              </IconButton>
              ดาวโหลดข้อมูลไฟล์ Excel
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, md: -12 }, ml: { xs: 0, md: 120 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#4DBCFA",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "92px" },
                height: "43px",
                fontSize: "15px",
              }}
            >
              <IconButton color="inherit" size="medium">
                <PrintIcon />
              </IconButton>
              พิมพ์
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: { xs: 2, md: 5 }, ml: { xs: 3, md: 5 } }}
      >
        <Typography
          sx={{ fontSize: "18px", lineHeight: "27px", fontWeight: 600 }}
        >
          รายงานยอดประเภทชำระเงิน
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: 2, overflowX: "auto", marginLeft: { xs: 1, sm: 2, md: 2 } }}
      >
        <Table
          sx={{
            borderRadius: "8px",
            width: { xs: "100%", sm: "100%", md: "99%" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: 6,
                  width: "10%",
                }}
              >
                ลำดับ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: 6,
                  width: "20%",
                }}
              >
                ประเภทชำระเงิน
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  width: "10%",
                }}
              ></TableCell>
              <TableCell
                colSpan={4}
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  width: "30%",
                }}
              ></TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: 10,
                  width: "10%",
                }}
              >
                จำนวนเงิน
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ flexDirection: "column" }}>
            {listSummaries && listSummaries.length > 0 ? (
              <>
                {listSummaries.map((summary) => (
                  <TableRow key={summary.id} sx={{ flexDirection: "column" }}>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: 12,
                      }}
                    >
                      {summary.id}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: 12,
                      }}
                    >
                      {summary.name}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: 12,
                      }}
                    />
                    <TableCell
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: 12,
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: 12,
                      }}
                    >
                      {summary.price - summary.discount}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
