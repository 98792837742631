import { MonetizationOnOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Paper, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";



interface Props {
    title: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> | string | any
    total: string
    icon: any
    bottomTitle: string
}

export default function Section(props: Props) {


    return (
        <Paper elevation={0} style={{ background: 'rgb(249 249 249)', padding: '20px', marginTop: '20px', display: 'flex', alignItems: 'center', borderRadius: 11 }}>
            <Box flexGrow={1}>
                <Typography variant="h6" style={{ fontWeight: 'bold', color: 'rgb(34 167 216)' }}>{props.title}</Typography>
                <Typography variant="h4" fontWeight={'bold'}>{props.total}</Typography>
                <Typography variant="subtitle2" color={'green'} fontSize={18}>{props.bottomTitle}&nbsp;</Typography>
            </Box>
            <IconButton color="primary">
                {props.icon}
            </IconButton>
        </Paper>
    )
}