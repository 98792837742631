import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextareaAutosize, Typography } from "@mui/material";
import * as React from 'react';
import { DataGrid, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { DeleteOutline, EditOutlined, PrivacyTipOutlined, TaskOutlined } from "@mui/icons-material";
import CreateOrUpdate from "./CreateOrUpdate";
import _ from "lodash";
import Swal from 'sweetalert2';
import { CategoryService } from "../../services/CategoryService";

const defaultImage = "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg";

export default function ManageCategory() {

    const [isDailog, setIsDialog] = React.useState<boolean>(false);
    const [editRecord, setEditRecord] = React.useState<any>();
    const [fullWidth, setFullWidth] = React.useState<boolean>(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    const [categorys, setCategorys] = React.useState<Array<any>>([])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'รหัส', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'name', headerName: 'หมวดหมู่', headerAlign: 'center', align: 'center', flex: 1 },
        {
            field: 'id1', headerName: ' ', headerAlign: 'center', align: 'center', flex: 1, renderCell: (row) => {
                return (
                    <>
                        <Button onClick={async () => {
                            setIsDialog(true)
                            setEditRecord(row.row)
                        }}>
                            <EditOutlined />
                        </Button>
                        <Button onClick={async () => deleteCategory(`${row.id}`)}>
                            <DeleteOutline />
                        </Button>
                    </>
                )
            }
        }
    ];


    React.useEffect(() => {
        getCategorys();
    }, [])


    const getCategorys = async () => {
        const res = await CategoryService.getCategorys()
        if (res?.status == 200) {
            const { categorys } = res.response
            setCategorys(categorys)
        }
    }

    const deleteCategory = async (categoryId: string) => {

        Swal.fire({
            title: "Are you sure to delete ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonColor: 'orange',
            confirmButtonText: "Confirm. Delete it!",
            denyButtonText: `Cancel`
        }).then(async (result) => {
            if (result.isConfirmed) {

                const res = await CategoryService.deleteCategory(categoryId)
                if (res?.status == 200) {
                    const indexCategory = _.findIndex(categorys, { id: +categoryId })
                    const _foods = [...categorys]
                    _foods.splice(indexCategory, 1)
                    setCategorys(_foods)
                }
            } else if (result.isDenied) {
            }
        });


    }

    return (
        <Grid container p={4} sx={{ bgcolor: 'white' }}>

            <Dialog
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        setIsDialog(false)
                    }
                }}
                open={isDailog}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{ sx: { borderRadius: "13px" } }}

            >

                <DialogTitle id="form-dialog-title">รายละเอียด</DialogTitle>
                <Divider></Divider>
                <DialogContent sx={{ p: 3 }}>


                    <CreateOrUpdate
                        editRecord={editRecord}
                        onClose={() => setIsDialog(false)}
                        onResponse={(category: any, isUpdate : boolean) => {

                            if(isUpdate){
                                const _category = category[0]
                                const foundIndex  = _.findIndex(categorys, { id : +_category.id})
                                if(foundIndex >= 0){
                                    const b = [...categorys];
                                    b[foundIndex] = _category
                                    setCategorys(b)
                                }
                            }else{
                                //create
                                setCategorys(p => [...category, ...p])
                            }


                          

                        }}
                    ></CreateOrUpdate>
                </DialogContent>
            </Dialog>

            <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
                <Typography
                    sx={{
                        color: "#FFFFFF",
                        fontWeight: 600,
                        fontSize: { xs: "18px", md: "24px" },
                    }}
                >
                    เพิ่มรายการหมวดหมู่
                </Typography>
            </Grid>

            <Box pt={2} pb={2} display={'flex'} justifyContent={'flex-end'} >
                <Button
                    onClick={() => setIsDialog(true)}
                    variant="contained" style={{ background: '#13ad13', color: 'white' }}>
                    + รายการหมวดหมู่
                </Button>
            </Box>

            <Box sx={{ width: { xs: 700, sm: 700, md: '100%' } }} style={{ overflow: 'auto' }} >
                <DataGrid
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 50, 100, 500]}
                    sx={{ width: { xs: 700, sm: 700, md: '100%' } }}
                    getRowId={(row: GridRowModel) => row.id}
                    getRowHeight={() => 'auto'}
                    disableEval
                    showCellVerticalBorder={true}
                    disableColumnMenu
                    disableColumnFilter
                    rows={categorys}
                    columns={columns}
                />
            </Box>
        </Grid >
    )
}