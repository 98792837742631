import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/StockRemaining/StockTable";
import ButtonList from "../../../components/StockManagement/StockRemaining/ButtonList";
import React from "react";
import { StockService } from "../../../services/StockService";

export default function StockRemainning() {

  const [stocks, setStocks] = React.useState<any[]>([])

  React.useEffect(() => {
    getStocks();
  }, [])

  const getStocks = async () => {
    const res = await StockService.getStocks();
    if (res?.status == 200) {
      const { stocks } = res.response;
      setStocks(stocks)
    }
  }

  const deleteStock = async (stockId: string) => {
    const res = await StockService.deleteStock(stockId);
    if (res?.status == 200) {
      getStocks();
    }
  }


  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          สต๊อกคงเหลือ
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <ButtonList />
        <Stack direction="row" spacing={2} sx={{ mt: 4 }} alignItems="end">
          <CustomInputText label="หมวด" />
          <CustomInputText label="รหัสสินค้า / เลขบาร์โค้ด" />
          <CustomInputText label="ชื่อสินค้า" />
          <CustomInputText label="จำนวนคงเหลือต่ำกว่า" />
          <CustomInputText label="จำนวนคงเหลือต่ำกว่า" />
          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              sx={{ bgcolor: "#FF9B28", px: "9px", py: "10px", width: "100%" }}
            >
              ค้นหา
            </Button>
          </Box>
        </Stack>
        <Box sx={{ mt: 4 }}>
          <StockTable
            stocks={stocks}
            onDelete={deleteStock}
          />
        </Box>
      </Paper>
    </>
  );
}
