import { Dialog, DialogTitle, Divider, DialogContent, DialogProps, FormControl, FormControlLabel, FormGroup, InputLabel, InputBase, Select, MenuItem, Grid, Button } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import axios from "axios";
import Swal from 'sweetalert2';

const AddEmployee = (props:any) => {
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3)
        },
        '& .MuiInputBase-input': {
            borderRadius: 10,
            position: 'relative',
            // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 16,
            width: '100%',
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
    const [position, setPosition] = useState<any>([]);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }, reset,
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
            position_id: '',
            salary: '',
            permissions: '[]'
        }
    });

    const AddNewEmployee = (data:any) => {
        axios.post(`${process.env.REACT_APP_API_URL}/employee/add`, {
            username: data.username,
            password: data.password,
            position_id: data.position_id,
            salary: data.salary,
            permissions: []
        }, {
            headers: {
                authorization: props.employee_token
            }
        }).then((response:any) => {
            if(response.data.message == 'OK')
            {
                reset({
                    username: '',
                    password: '',
                    position_id: '',
                    salary: '',
                    permissions: '[]'
                })
                props.setIsOpenDialogAddEmployee(false);
                props.reloadEmployee();
                Swal.fire({
                    icon: "success",
                    text: 'เพิ่มพนักงานเรียบร้อยแล้ว'
                });
            }
            else
            {
                Swal.fire({
                    icon: "error",
                    text: response.data?.message
                });
            }
        })
    }

    useEffect(() => {
        setIsOpenDialog(props.isOpenDialog);
    }, [props])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/employee/get/position`, {
            headers: {
                authorization: props.employee_token
            }
        }).then((response:any) => {
            if(response.data.message == 'OK')
            {
                setPosition(response.data.position)
            }
        })
    }, [])

    return (
        <>
            <Dialog
                onClose={(event, reason) => {
                    props.setIsOpenDialogAddEmployee(false)
                }}
                open={isOpenDialog}
                fullWidth={true}
                maxWidth={maxWidth}
                PaperProps={{ sx: { borderRadius: "13px" } }}

            >

                <DialogTitle id="form-dialog-title">เพิ่มพนักงาน</DialogTitle>
                <Divider></Divider>
                <DialogContent sx={{ p: 3 }}>
                    <form onSubmit={handleSubmit((data) => AddNewEmployee(data))}>
                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input-username">
                                ชื่อผู้ใช้
                            </InputLabel>
                            <BootstrapInput
                                {...register('username', { required: true })}
                                defaultValue="" id="bootstrap-input-username" placeholder="ชื่อผู้ใช้ ยกตัวอย่าง somtum" />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input-password">
                                รหัสผ่าน
                            </InputLabel>
                            <BootstrapInput
                                {...register('password', { required: true })}
                                defaultValue="" id="bootstrap-input-password" type="password" placeholder="รหัสผ่านของผู้ใช้งานนี้" />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input-salary">
                                ค่าจ้าง
                            </InputLabel>
                            <BootstrapInput
                                {...register('salary', { required: true })}
                                defaultValue="" id="bootstrap-input-salary" placeholder="ค่าจ้าง ยกตัวอย่าง 1000.00" />
                        </FormControl>

                        <FormControl sx={{ mb: 2 }} variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input">
                                ตำแหน่ง
                            </InputLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        required
                                        onChange={onChange}
                                        value={value}
                                        displayEmpty
                                        size={'small'}
                                        inputProps={{ "aria-label": "Without label" }}
                                        style={{ borderRadius: 10, color: 'black', minWidth: 150, width: '100%' }}
                                    >

                                        {
                                            position.map((item:any, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        }

                                    </Select>
                                )}
                                control={control}
                                name={'position_id'}
                            />
                        </FormControl>
                        <br></br>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Button
                                    onClick={() => props.setIsOpenDialogAddEmployee(false)}
                                    fullWidth
                                    size={"large"}
                                    style={{
                                        border: "1px solid #ff6801", color: "#ff6801",
                                        borderRadius: 25,
                                        padding: '10px 35px 10px 35px'
                                    }} >ยกเลิก</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Button type="submit" fullWidth size={"large"} style={{
                                    backgroundColor: "#ff6801", color: "white",
                                    borderRadius: 25,
                                    padding: '10px 35px 10px 35px'
                                }} >เพิ่ม</Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddEmployee;