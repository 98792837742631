import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/DeductionStockSetting/StockTable";
import CustomSelect from "../../../components/Select/CustomSelect";

export default function AddStock() {
  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          ตั้งค่าตัดสต๊อก
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }} alignItems="end">
          <Stack direction="column">
            <Typography>หมวด</Typography>
            <CustomSelect dataSource={[]} onChange={() => {}} />
          </Stack>
          <CustomInputText label="รหัสสินค้า / เลขบาร์โค้ด" />
          <CustomInputText label="ชื่อสินค้า" />
          <Stack direction="column">
            <Typography>สถานะการขาย</Typography>
            <CustomSelect dataSource={[]} onChange={() => {}} />
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              sx={{ bgcolor: "#FF9B28", px: "9px", py: "10px", width: "100%" }}
            >
              ค้นหา
            </Button>
          </Box>
        </Stack>
        <Box sx={{ mt: 4 }}>
          <StockTable />
        </Box>
      </Paper>
    </>
  );
}
