import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton, Paper, Stack } from "@mui/material";
import { Delete } from "@mui/icons-material";
import TaskList from "../../Icon/TaskList";



interface Props {
  stocks: any[]
  onDelete: (stockId: string) => void;
}
export default function StockTable(props: Props) {


  const columns: GridColDef[] = [
    {
      field: "barcode",
      headerName: "รหัสบาร์โค้ด",
      type: "string",
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "รายการสินค้า",
      minWidth: 250,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "หมวด",
      type: "string",
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "quantity",
      headerName: "คงเหลือ",
      type: "string",
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "unit",
      headerName: "หน่วย",
      type: "string",
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "จัดการ",
      headerName: "จัดการ",
      type: "string",
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: (row) => (
        <Stack direction="row" justifyContent="center" sx={{ flexGrow: 1 }}>
          <IconButton
            sx={{
              bgcolor: "#FFB969",
              borderRadius: "8px",
              ":hover": { bgcolor: "#FFB969" },
              mr: 2,
            }}
          >
            <TaskList />
          </IconButton>
          <IconButton
            onClick={() =>{
              props.onDelete(`${row.id}`)
            }}
            sx={{
              bgcolor: "#FF9696",
              borderRadius: "8px",
              ":hover": { bgcolor: "#FF9696" },
            }}
          >
            <Delete sx={{ color: "white" }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Paper style={{ height: 400, width: "100%" }}>
      <DataGrid
        showColumnVerticalBorder
        showCellVerticalBorder
        rows={props.stocks}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </Paper>
  );
}
